<template>
  <div class="branches">
    <header class="aboutUs_header">
      <div class="container">
        <div class="headerContainer">
          <div class="side">
            <h2>{{$t('home.homeOurBranches')}}</h2>
            <div class="line"></div>
            <div class="breadCrobs">
              <router-link to="/">{{$t('landing.Home')}}</router-link>
              <span>>></span>
              <h3>{{$t('home.homeOurBranches')}}</h3>
            </div>
          </div>
          <div class="side">
            <img src="../../../assets/images/aboutUslogo.png" alt="about us" />
          </div>
        </div>
      </div>
    </header>

    <div class="title">
      <h2>{{$t('landing.OurBranches')}}</h2>
    </div>

    <div class="branches_filters">
      <div class="container">
        <div class="inputs">
          <div class="input_container">
            <form @submit.prevent="NameFilter">
              <input type="text" :placeholder="$t('medicalJournal.search')" v-model="branchName"/>
              <button class="saveBtn">{{$t('medicalJournal.search')}}</button>
            </form>
          </div>
          <!-- <div class="input_container">
            <form @submit.prevent="CityFilter">
              <input type="text" placeholder="بحث بالمدينة" v-model="city"/>
              <button class="saveBtn">بحث</button>
            </form>
          </div> -->
          
          <button @click="Allbranch = true">
            {{$t('branches.OnTheMap')}}
            <img src="../../../assets/images/mapLocation.png" alt="" />
          </button>
          <div class="AllLocations" v-if="Allbranch == true" >
            <div class="close" @click="Allbranch = false">X</div>
            <div class="map">
              <GoogleMapAllLocations :locations="locations" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="branches_information">
      <div class="container">
        <div class="branches_container">
          <div class="branch" v-for="branch in branches" :key="branch.id">
            <div class="branch_spacing">
              <div class="img_container">
                <img src="../../../assets/images/branch.png" alt="" />
              </div>
              <div class="address">
                <h3>{{branch.translations[0].name}}</h3>
                <h3>{{branch.address+ ' ' +branch.city}}</h3>
              </div>
              <div class="phone">
                <h3>{{$t('investor.CellNo')}}</h3>
                <h3>{{branch.mobile}}</h3>
              </div>
              <div class="times" v-if="branch.is_24_hour == false">
                <h4>{{$t('branches.branchesWorkingHours')}}</h4>
                <h3> من {{branch.from_time}} إلي {{branch.to_time}} </h3>
                <h3 v-if="branch.time_shifts.length > 0"> من {{branch.time_shifts[0].time_from}} إلي {{branch.time_shifts[0].time_to}} </h3>
              </div>
              <div class="times" v-if="branch.is_24_hour == true">
                <h4>{{$t('branches.branchesWorkingHours')}}</h4>
                <h3> 24 ساعة</h3>
              </div>
              <div class="services">
                <h4>{{$t('branches.AdditionalBranchServices')}}</h4>
                <h3 v-for="service in branch.services" :key="service.id">
                  <div class="true">
                    <img src="../../../assets/images/greenTrue.png" alt="" />
                  </div>
                  {{service.translations[0].name}}
                </h3>
              </div>
            </div>
            <div>
              <button target="_blank" @click="branch.mapCollabs = true">
                {{$t('branches.OnTheMap')}}
                <img src="../../../assets/images/mapLocation.png" alt="" />
              </button>
              <div class="map_container" v-if="branch.mapCollabs == true" @click="branch.mapCollabs = false">
                <div class="map">
                  <GoogleMap :latitude="parseFloat(branch.lat)" :longitude="parseFloat(branch.long)" :title="branch.translations[0].name" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios';
import Request from '../../../services/Request';
import GoogleMap from '../../GoogleMap.vue';
import GoogleMapAllLocations from './GoogleMapAllLocations.vue';
export default {
  name: "Branches",
  components: {
    GoogleMap,
    GoogleMapAllLocations
  },
  data(){
    return{
      Allbranch: false,
      branches: [],
      locations: [],
      branchName: '',
      city: '',
      
    }
  },
  mounted(){
    this.getBranches();
   
  },
  methods:{
    getBranches(){
      axios.create({
        baseURL: 'https://app.almujtama.com.sa/api',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ localStorage.getItem('token'),
          // localization: store.state.localization
          'locale' : localStorage.getItem('lang')
        }
      })
      .get('/branches')
      .then(res => {
        this.branches = res.data
        this.branches = res.data.map(developer => {
          return {
              ...developer,
              mapCollabs: false,
          };
          
        });
        res.data.map(developer => {
          this.locations.push({
          position: {lat: parseFloat(developer.lat), lng: parseFloat(developer.long)},
          title: developer.translations[0].name
        })
        });
      });
    },
    NameFilter(){
      Request.getFilteredData('api/branches', {
        search: this.branchName
      })
      .then(res => {
        this.branches = res.data
      })
    },
    CityFilter(){
      Request.getFilteredData('api/branches', {
        city: this.city
      })
      .then(res => {
        this.branches = res.data
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.aboutUs_header {
  background-color: #78a28f;
  padding: 100px 0;
  @media (max-width: 991px) {
    padding: 50px 0;
  }
  .headerContainer {
    display: flex;
    justify-content: space-between;
    @media (max-width: 576px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .side {
      h2 {
        font-size: 34px;
        font-weight: 800;
        color: #ffffff;
        margin-bottom: 30px;
      }
      .breadCrobs {
        display: flex;
        align-items: center;
        margin-top: 100px;
        @media (max-width: 576px) {
          margin: 30px 0;
        }
        a,
        span {
          font-size: 18px;
          font-weight: 600;
          color: #d8d253;
          margin-left: 10px;
        }
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          margin-bottom: 0;
        }
      }
    }
  }
}

.title {
  h2 {
    font-size: 42px;
    font-weight: 800;
    color: #6da48d;
    text-align: center;
    margin: 20px 0;
  }
}

.branches_filters {
  .inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: center;
    }
    .input_container {
      position: relative;
      margin-left: 30px;
      @media (max-width: 991px) {
        margin-left: 0;
      }
      form{
        display: flex;
        button{
          width: 70px;
          margin: 0 30px 10px 0;
          
        }
      }
    }
    select,
    input {
      border: 1px solid #dddddd;
      border-radius: 5px;
      width: 271px;
      padding: 10px;
      margin-bottom: 10px;
      @media (max-width: 1199px) {
        width: 177px;
      }
    }
    > button {
      background-color: #d8d253;
      width: 243px;
      font-weight: 600;
      margin: 0 30px 10px 0;
      padding: 10px;
      border: 0;
      font-size: 16px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      @media (max-width: 991px) {
          margin: 0;
          width: 274px;
      }
      img {
        margin-right: 10px;
      }
    }
  }
}

.branches_information {
  .branches_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 0px 10px 50px #21343014;
    padding: 100px;
    @media (max-width: 1200px) {
      padding: 50px 20px;
    }
    .branch {
      box-shadow: 0px 10px 50px #21343014;
      padding: 10px;
      margin-bottom: 30px;
      width: 31%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .img_container{
        width: 70%;
        margin: auto;
      }
      @media (max-width: 991px) {
        width: 45%;
      }
      @media (max-width: 678px) {
        width: 100%;
      }
      .img_container {
        img {
          margin: 0 auto 10px auto;
          display: block;
          max-width: 100%;
        }
      }
      .address,
      .phone,
      .times {
        border-bottom: 1px solid #ddd;
        padding: 10px 0;
        text-align: center;
      }
      .services {
        padding: 10px;
      }
      h3 {
        font-size: 14px;
        color: #78a28f;
        text-align: center;
        font-weight: 600;

        .true {
          width: 14px;
          height: 14px;
          background-color: #78a28f;
          position: relative;
          border-radius: 50%;
          margin-left: 10px;
          img {
            position: absolute;
            bottom: 1px;
            left: 1px;
          }
        }
      }
      h4 {
        font-size: 14px;
        font-weight: 600;
        color: #2b5933;
        text-align: center;
      }
      .services {
        h3 {
          display: flex;
          align-items: center;
        }
      }
      button {
        width: 80%;
        border: 0;
        background-color: #d8d253;
        padding: 10px;
        font-size: 16px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px auto 10px auto;
        border-radius: 5px;
        img {
          margin-right: 10px;
        }
      }

      .map_container{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
        background-color: #00000033;
        .map{
          position: relative;
          z-index: 30;
          width: 500px;
          height: 500px;
          background-color: #FFF;
        }
      }
    }
  }
}
.en{
  .breadCrobs{
    a,
    span{
      margin: 0 10px 0 0;
    }
  }
  form{
    .saveBtn{
      margin-left: 10px;
    }
  }
  .branches_information .branches_container .branch h3 .true{
    margin: 0 10px 0 0;
  }
  .branches_information .branches_container .branch a img{
    margin: 0 0 0 10px;
  }
}

.AllLocations{
  position: fixed;
  top: 100px;
  left: 100px;
  bottom: 100px;
  right: 100px;
  box-shadow: 0 0 10px #DDD;
  background: #FFF;
  border-radius: 10px;
  .close{
    font-size: 30px;
    font-weight: 600;
    padding: 3px 0 3px 10px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }
  .map{
    height: 100%;
    .vue-map-container{
      height: 100% !important;
    }
  }
}
</style>