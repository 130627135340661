<template>
    <div>
        <HeaderBg :img="img" title="إضافة حي" />

        <div class="form_container">
            <form @submit.prevent="addDistrict">
                <div class="input_container">
                    <label> الحي باللغه العربية</label>
                    <input type="text" v-model="district.translation[0].name">
                </div>
                <div class="input_container">
                    <label> الحي باللغه الإنجليزية</label>
                    <input type="text" v-model="district.translation[1].name">
                </div>
                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.type == 'edit' ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Request from '../../../services/Request';
export default {
    name :'AddDistrict',
    components: {HeaderBg},
    data(){
        return{
            img: require('../../../assets/images/locationLarg.png'),
            postLoaded: false,
            district: {
                city_id : this.$route.params.id,
                translation : [
                    {
                        name : "",
                        local : "ar"
                    },
                    {
                        name : "",
                        local : "en"
                    }
                ]
            },
            errors: [],
            ErrorCheck: false,
        }
    },
    mounted(){
        this.getDistrict();
    },
    methods:{
        addDistrict(){
            this.postLoaded = true;
            if(this.$route.params.cityId !== undefined){
                
                axios.put(`https://app.almujtama.com.sa/admin/district/${this.$route.params.cityId}`, this.district, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/store-admin/cities/district/'+ this.$route.params.id)
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            } else {
                axios.post('https://app.almujtama.com.sa/admin/district', this.district, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push(`/store-admin/cities/district/${this.$route.params.id}`)
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            }
        },
        getDistrict(){
            if(this.$route.params.cityId !== undefined){
                Request.fetch(`admin/district/${this.$route.params.cityId}`)
                .then((response) => {
                    console.log(response.data.data.translation[0])
                    this.district.translation[0].name = response.data.data.translation[0].name
                })
                .catch(err => {
                    if(Request.statusIsFaield(err)){
                        this.$router.push('/login')
                        localStorage.removeItem('token')
                    }
                });
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.form_container{
    background-color: #FFF;
    border-radius: 10px;
    margin: 40px auto;
    padding: 70px 100px;
    width: 482px;
    @media (max-width: 576px) {
        width: 97%;
        padding: 30px;
    }
    form{
        > div{
            label{
                display: block;
                text-align: center;
                font-size: 16px;
                color: #717171;
                font-weight: 600;
                margin-bottom: 10px;
            }
            input{
                border: 1px solid #71717191;
                font-size: 16px;
                padding: 5px 10px;
                border-radius: 10px;
                display: block;
                margin: auto;
                width: 100%;
            }
        }
        button{
            margin: 30px auto 0 auto;   
        }
    }
}
</style>