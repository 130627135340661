<template>
    <div>
        <!-- ArticlesSections.vue -->
        <HeaderBg title="أقسام المقالات" description="أقسام رئيسية" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteCourse" 
            messege="هل أنت متأكد من مسح القسم   ؟"
        />
        <header class="admin_content_header">
            <h2></h2>
            <div class="search">
                
            </div>
            <router-link to="/admin/articles-sections/add-articles-sections" v-if="add_articles_sections_permission == true"> أضف قسم جديد </router-link>
        </header>
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> الأسم </th>
                        <th> </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="articlesSection in articlesSections" :key="articlesSection.id">
                        <td> {{articlesSection.translation[0].name}} </td>
                        <td>
                            <div class="actionsContainer">
                                <button @click="() => subSections(articlesSection.id)">أقسام فرعية</button>
                                <div class="options_container" v-if="edit_articles_sections_permission == true || delete_articles_sections_permission == true">
                                    <img src="../../../assets/images/selectIcon.png" alt="">
                                    <div class="hidden_options">
                                        <button v-if="edit_articles_sections_permission == true" @click="() => editBranch(articlesSection.id)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                                        <button v-if="delete_articles_sections_permission == true" @click="() => deleteData(articlesSection.id)"> <img src="../../../assets/images/delete-text.png" alt=""> حذف </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="articlesSections.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import store from "@/store/index.js";
export default {
    name: 'ArticlesSections',
    components: {HeaderBg, Alert, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            articlesSections: [],
            deleteID: '',
            alertToggle: false,

            // permissions
            add_articles_sections_permission: false,
            edit_articles_sections_permission: false,
            delete_articles_sections_permission: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    beforeMount(){
        var permissions = JSON.parse(localStorage.getItem("permissions"));

        if(permissions.includes('list articles sections')){
            console.log('permission')
        } else {
            this.$router.push('/login')
        }

        if(permissions.includes('add articles sections')){
            this.add_articles_sections_permission = true
        } 
        if(permissions.includes('update articles sections')) {
            this.edit_articles_sections_permission = true
        } 
        if(permissions.includes('delete articles sections')) {
            this.delete_articles_sections_permission = true
        } 
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getArticlesSections();
    },
    methods:{
        subSections(id){
            this.$router.push(`/admin/articles-sub-sections/${id}`)
        },
        getArticlesSections(){
            Request.fetch('admin/magazineCategory', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.articlesSections = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        editBranch(id){
            this.$router.push(`/admin/articles-sections/add-articles-sections/${id}`)
        },
            // delete data
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteCourse(){
            Request.delete('admin/magazineCategory',this.deleteID)
            .then( () => {
                this.getArticlesSections();
            })
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getArticlesSections();
            } else{
                this.apiParams = `?page=${val}`;
                this.getArticlesSections();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.actionsContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button{
        background-color: #78A28F;
        border: 10px;
        padding: 5px 20px;
        color: #FFF;
        border: 0;
        margin-left: 20px;
        border-radius: 10px;
    }
}
</style>