<template>
    <div>
        <div class="banner_form_container full_container">
            <form @submit.prevent="updateOfferBanner">
                <div class="bannerAddNew" v-for="(banner, index) in OfferBanner" :key="index">
                    <div class="upload_img">
                        <span>  الصورة الأولي :</span>
                        <div class="img_container">
                            <img src="../../../../../assets/images/replaceImg.svg" alt="" v-if="banner.showImage1 == ''">
                            <img :src="banner.showImage1" alt="" v-if="banner.showImage1 !== ''">
                            <div class="photo_upload">
                                <div class="upladImg">
                                    <div class="upload">
                                        <label for="">
                                            <img src="../../../../../assets/images/inputFile.svg" alt="">
                                            <input type="file" @change="(e) => uploadOfferBannerImg1(e, index)">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="banner_title"></div>
                    <div class="banner_title">
                        البانر الأول
                    </div>
                    <div class="input_container">
                        <label> : الرابط الأول </label>
                        <input type="text" v-model="banner.first_url">
                    </div>
                    <div class="input_container">

                    </div>
                    <div class="input_container">
                        <label> : الإسم الاول باللغة العربية</label>
                        <input type="text" v-model="banner.first_name_ar">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الأول باللغة الإنجليزية </label>
                        <input type="text" v-model="banner.first_name_en">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الثاني باللغة العربية</label>
                        <input type="text" v-model="banner.second_name_ar">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الثاني باللغة الإنجليزية </label>
                        <input type="text" v-model="banner.second_name_en">
                    </div>
                    <div class="banner_title">
                        البانر الثاني
                    </div>
                    <div class="upload_img">
                        <span>  الصورة الثانية :</span>
                        <div class="img_container">
                            <img src="../../../../../assets/images/replaceImg.svg" alt="" v-if="banner.showImage2 == ''">
                            <img :src="banner.showImage2" alt="" v-if="banner.showImage2 !== ''">
                            <div class="photo_upload">
                                <div class="upladImg">
                                    <div class="upload">
                                        <label for="">
                                            <img src="../../../../../assets/images/inputFile.svg" alt="">
                                            <input type="file" @change="(e) => uploadOfferBannerImg2(e, index)">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input_container"></div>
                    <div class="input_container">
                        <label> : الرابط الثاني </label>
                        <input type="text" v-model="banner.second_url">
                    </div>
                    <div class="input_container">
                        
                    </div>
                    <div class="input_container">
                        <label> : الإسم الثالث باللغة العربية </label>
                        <input type="text" v-model="banner.third_name_ar">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الثالث باللغة الانجليزية</label>
                        <input type="text" v-model="banner.third_name_en">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الرابع باللغة العربية </label>
                        <input type="text" v-model="banner.fourth_name_ar">
                    </div>
                    <div class="input_container">
                        <label> : الإسم الرابع باللغة الإنجليزية </label>
                        <input type="text" v-model="banner.fourth_name_en">
                    </div>

                </div>
                <div class="add_new" @click="addOfferBanner">
                    <div class="blus_icon">+</div>
                    إضافة لافتة اعلانية اخرى 
                </div>
                
               <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import Request from '../../../../../services/Request';
import axios from 'axios';
import store from "@/store/index.js";
export default {
    name: 'OfferBanner',
    data(){
        return{
            errors: [],
            ErrorCheck: false,
            postLoaded: false,
            loadingRequest: false,
            deleteID: '',
            alertToggle: false,
            OfferBanner: [],
        }
    },
    mounted(){
      this.getOffersBanner();
    },
    methods:{
        addOfferBanner(){
            this.OfferBanner.push(
                {
                    first_image: null,
                    first_url: '',
                    second_image: null,
                    second_url: '',
                    id: '',
                    first_name_ar: '',
                    first_name_en: '',
                    second_name_ar: '',
                    second_name_en: '',
                    third_name_ar: '',
                    third_name_en: '',
                    fourth_name_ar: '',
                    fourth_name_en: '',
                    showImage1: '',
                    showImage2: ''
                }
            )
        },
        uploadOfferBannerImg1(e, index){
            this.OfferBanner[index].first_image = e.target.files[0];
            this.OfferBanner[index].showImage1 = URL.createObjectURL(e.target.files[0]);
        },
        uploadOfferBannerImg2(e, index){
            this.OfferBanner[index].second_image = e.target.files[0];
            this.OfferBanner[index].showImage2 = URL.createObjectURL(e.target.files[0]);
        },

        updateOfferBanner(){
            this.postLoaded = true
            const formData = new FormData();

            if(this.OfferBanner !== []){
                this.OfferBanner.map((data, index) => {
                    if(this.OfferBanner[index].first_image !== null) {
                        formData.append(`offers[${index}][first_image]`, this.OfferBanner[index].first_image);
                    }
                    if(this.OfferBanner[index].second_image !== null) {
                        formData.append(`offers[${index}][second_image]`, this.OfferBanner[index].second_image);
                    }
                    
                    formData.append(`offers[${index}][first_url]`, this.OfferBanner[index].first_url);
                    formData.append(`offers[${index}][second_url]`, this.OfferBanner[index].second_url);
                    formData.append(`offers[${index}][id]`, this.OfferBanner[index].id);
                    formData.append(`offers[${index}][first_name_ar]`, this.OfferBanner[index].first_name_ar);
                    formData.append(`offers[${index}][first_name_en]`, this.OfferBanner[index].first_name_en);
                    formData.append(`offers[${index}][second_name_ar]`, this.OfferBanner[index].second_name_ar);
                    formData.append(`offers[${index}][second_name_en]`, this.OfferBanner[index].second_name_en);
                    formData.append(`offers[${index}][third_name_ar]`, this.OfferBanner[index].third_name_ar);
                    formData.append(`offers[${index}][third_name_en]`, this.OfferBanner[index].third_name_en);
                    formData.append(`offers[${index}][fourth_name_ar]`, this.OfferBanner[index].fourth_name_ar);
                    formData.append(`offers[${index}][fourth_name_en]`, this.OfferBanner[index].fourth_name_en);
                })
            }

            formData.append('_method', 'PUT');
            axios.post(`${store.state.domain}/admin/updateOfferBanner`, formData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.$router.push('/store-admin/pannars')
                this.postLoaded = false
            })  
            .catch(err =>  {
                this.ErrorCheck = true;
                this.errors = err.response.data.errors;
                this.postLoaded = false;
            })
        },

        getOffersBanner(){
            axios.get(`${store.state.domain}/admin/offerBanner`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                response.data.data.map(data => {
                    this.OfferBanner.push(
                        {
                            first_image : null,
                            first_url: data.first_url,
                            second_image: null,
                            second_url: data.second_url,
                            id: data.id,
                            first_name_ar: data.first_name_ar,
                            first_name_en: data.first_name_en,
                            second_name_ar: data.second_name_ar,
                            second_name_en: data.second_name_en,
                            third_name_ar: data.third_name_ar,
                            third_name_en: data.third_name_en,
                            fourth_name_ar: data.fourth_name_ar,
                            fourth_name_en: data.fourth_name_en,
                            showImage1: data.first_image,
                            showImage2: data.second_image,
                        }
                    )
                })
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                this.$router.push('/login')
                localStorage.removeItem('token')
            }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.upload_img{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    span{
        font-size: 16px;
        color: #656565;
        margin-left: 50px;
    }
    .img_container{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #FAFAFA;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
            width: 100%;
        }
        .photo_upload{
            
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            right: 0;
            img{
                width: 70%;
            }
        }
    }
}

.full_container{
    width: 80%;

    form > .bannerAddNew{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .input_container,
    .upload_img{
        width: 45%;
        margin-bottom: 30px;
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    .banner_title{
        width: 100%;
        text-align: center;
        font-size: 26px;
        font-weight: 600;
        margin: 20px 0;
    }
}
</style>