<template>
<!-- Admin.vue -->
    <div class="admin_page">
        <div class="side_menu" :class="showSideMenu == true ? 'menuShow' : 'menuHide'">
            <div class="toggleMenu" @click="toggleMenu">
                <b-icon icon="caret-right-fill" aria-hidden="true"></b-icon>
            </div>
            <div class="logo">
                <img src="../../assets/images/newLogo.png" alt="">
            </div>
            <router-link to="/admin/main" class="store_manage">إدارة الصفحة الرئيسية</router-link>

            <div class="links bigScreen" @click="linkUrlCheck">
                <ul>
                    <li v-if="store_management == true">
                        <router-link to="/store-admin/statistics" :class="hoverType == 'statistics' ? 'aciveLink' : ''"> 
                            <div class="img_container"><img src="../../assets/images/minpage.png" alt=""></div> 
                            الرئيسية 
                        </router-link>
                    </li>
                    <li v-if="store_list_orders == true">
                        <router-link to="/store-admin/requests" :class="hoverType == 'requests' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/storeAdminDescription.png" alt=""></div> 
                             الطلبات 
                        </router-link>
                    </li>
                    <li v-if="store_prescriptions == true">
                        <router-link to="/store-admin/prescriptions/mine" :class="hoverType == 'prescriptions' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/prescription.png" alt=""></div> 
                             الوصفات 
                        </router-link>
                    </li>
                    <li v-if="store_cities == true">
                        <router-link to="/store-admin/cities" :class="hoverType == 'cities' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/location22.png" alt=""></div> 
                            مدن التوصيل
                        </router-link>
                    </li>
                    <li v-if="store_linking_disticts == true">
                        <router-link to="/store-admin/linking-disticts" :class="hoverType == 'linking-disticts' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/location22.png" alt=""></div> 
                            ربط الأحياء
                        </router-link>
                    </li>
                    <li>
                    <li @click="changeArrow" v-if="store_products == true || store_categories == true">
                        <router-link to="" :class="hoverType == 'test' ? 'aciveLink' : ''" v-b-toggle="'collapse-'" variant="primary">
                            <div class="img_container"><img src="../../assets/images/products.png" alt=""></div> 
                            إدارة المنتجات
                            <div class="mx-2">
                                <b-icon v-if="productsArrow == true" icon="chevron-down" aria-hidden="true"></b-icon>
                                <b-icon v-if="productsArrow == false" icon="chevron-up" aria-hidden="true"></b-icon>
                            </div>
                        </router-link>
                        <b-collapse :id="'collapse-'" class="w-100">
                        <div>
                            <ul>
                                <li v-if="store_products == true">
                                    <router-link to="/store-admin/products" :class="hoverType == 'products' ? 'aciveLink' : ''">
                                        المنتجات
                                    </router-link>
                                </li>
                                <li v-if="store_categories == true">
                                    <router-link to="/store-admin/categories" :class="hoverType == 'categories' ? 'aciveLink' : ''">
                                        أقسام المنتجات
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        </b-collapse>
                    </li>
                    <li v-if="store_companies == true">
                        <router-link to="/store-admin/companies" :class="hoverType == 'companies' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/tag.png" alt=""></div> 
                            الشركات
                        </router-link>
                    </li>
                    <li v-if="store_out_of_stock == true">
                        <router-link to="/store-admin/out-of-stock" :class="hoverType == 'out-of-stock' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/stock.png" alt=""></div> 
                            المنتجات المنتهية من المخزون
                        </router-link>
                    </li>
                    <li v-if="store_users == true">
                        <router-link to="/store-admin/users" :class="hoverType == 'users' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/users.png" alt=""></div> 
                            المستخدمين
                        </router-link>
                    </li>
                    <li v-if="store_copouns == true">
                        <router-link to="/store-admin/copouns" :class="hoverType == 'copouns' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/discount.png" alt=""></div> 
                            كود الخصم
                        </router-link>
                    </li>
                    <li v-if="store_reports == true">
                        <router-link to="/store-admin/reports" :class="hoverType == 'reports' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/report.jpg" alt="">
                                </div> 
                            التقارير
                        </router-link>
                    </li>
                    <li v-if="store_pannars == true">
                        <router-link to="/store-admin/pannars" :class="hoverType == 'pannars' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/banner-design.png" alt="">
                                </div> 
                            اللافتات
                        </router-link>
                    </li>
                    <li>
                        <router-link v-if="store_Offers == true" to="/store-admin/offers" :class="hoverType == 'offers' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/sale.svg" alt="">
                                </div> 
                            العروض
                        </router-link>
                    </li>
                    <li>
                        <router-link v-if="store_evaluation == true" to="/store-admin/comments" :class="hoverType == 'comments' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/sale.svg" alt="">
                                </div> 
                            تقييمات المتجر
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/store-admin/availability" :class="hoverType == 'availability' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/sale.svg" alt="">
                                </div> 
                            اخبرني عندما يتوفر
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/login" >
                            <div class="img_container"></div>
                            تسجيل الخروج
                        </router-link>
                    </li>
                 
                </ul>
                
            </div>
            <div class="links smallScreen" @click="linkUrlCheck">
                <ul>
                    <li @click="toggleMenu" v-if="store_management == true">
                        <router-link to="/store-admin/statistics" :class="hoverType == 'statistics' ? 'aciveLink' : ''"> 
                            <div class="img_container"><img src="../../assets/images/minpage.png" alt=""></div> 
                            الرئيسية 
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_list_orders == true">
                        <router-link to="/store-admin/requests" :class="hoverType == 'requests' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/storeAdminDescription.png" alt=""></div> 
                             الطلبات 
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_prescriptions == true">
                        <router-link to="/store-admin/prescriptions/mine" :class="hoverType == 'prescriptions' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/prescription.png" alt=""></div> 
                             الوصفات 
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_cities == true">
                        <router-link to="/store-admin/cities" :class="hoverType == 'cities' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/location22.png" alt=""></div> 
                            مدن التوصيل
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_linking_disticts == true">
                        <router-link to="/store-admin/linking-disticts" :class="hoverType == 'linking-disticts' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/location22.png" alt=""></div> 
                            ربط الأحياء
                        </router-link>
                    </li>
                    <li>
                    <li @click="changeArrow" v-if="store_products == true || store_categories == true">
                        <router-link to="" :class="hoverType == 'test' ? 'aciveLink' : ''" v-b-toggle="'collapse-'" variant="primary">
                            <div class="img_container"><img src="../../assets/images/products.png" alt=""></div> 
                            إدارة المنتجات
                            <div class="mx-2">
                                <b-icon v-if="productsArrow == true" icon="chevron-down" aria-hidden="true"></b-icon>
                                <b-icon v-if="productsArrow == false" icon="chevron-up" aria-hidden="true"></b-icon>
                            </div>
                        </router-link>
                        <b-collapse :id="'collapse-'" class="w-100">
                        <div>
                            <ul>
                                <li @click="toggleMenu" v-if="store_products == true">
                                    <router-link to="/store-admin/products" :class="hoverType == 'products' ? 'aciveLink' : ''">
                                        المنتجات
                                    </router-link>
                                </li>
                                <li @click="toggleMenu" v-if="store_categories == true">
                                    <router-link to="/store-admin/categories" :class="hoverType == 'categories' ? 'aciveLink' : ''">
                                        أقسام المنتجات
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        </b-collapse>
                    </li>
                    <li @click="toggleMenu" v-if="store_companies == true">
                        <router-link to="/store-admin/companies" :class="hoverType == 'companies' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/tag.png" alt=""></div> 
                            الشركات
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_out_of_stock == true">
                        <router-link to="/store-admin/finished-products-from-stock" :class="hoverType == 'out-of-stock' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/stock.png" alt=""></div> 
                            المنتجات المنتهية من المخزون
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_users == true">
                        <router-link to="/store-admin/users" :class="hoverType == 'users' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/users.png" alt=""></div> 
                            المستخدمين
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_copouns == true">
                        <router-link to="/store-admin/copouns" :class="hoverType == 'copouns' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/discount.png" alt=""></div> 
                            كود الخصم
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_reports == true">
                        <router-link to="/store-admin/reports" :class="hoverType == 'reports' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/report.jpg" alt="">
                                </div> 
                            التقارير
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_pannars == true">
                        <router-link to="/store-admin/pannars" :class="hoverType == 'pannars' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/banner-design.png" alt="">
                                </div> 
                            اللافتات
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_Offers == true" >
                        <router-link to="/store-admin/offers" :class="hoverType == 'offers' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/sale.svg" alt="">
                                </div> 
                            العروض
                        </router-link>
                    </li>
                    <li @click="toggleMenu" v-if="store_evaluation == true" >
                        <router-link to="/store-admin/comments" :class="hoverType == 'comments' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/sale.svg" alt="">
                                </div> 
                            تقييمات المتجر
                        </router-link>
                    </li>
                    <li @click="toggleMenu" >
                        <router-link to="/store-admin/availability" :class="hoverType == 'availability' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/sale.svg" alt="">
                                </div> 
                            اخبرني عندما يتوفر
                        </router-link>
                    </li>
                     <li @click="toggleMenu">
                        <router-link to="/login" >
                            <div class="img_container"></div>
                            تسجيل الخروج
                        </router-link>
                    </li>
                 
                </ul>
                
            </div>

        </div>
        <div class="content" :class="showSideMenu == true ? 'contentShow' : 'contenthide'">
            <div class="navBar">
                <div class="side">
                    <div class="menu_icon" @click="toggleMenu">
                        <img src="../../assets/images/menuIcon.png" alt="">
                    </div>
                    <div class="sync_container" @click="refreshPage">
                        <img src="../../assets/images/sync.png" alt="">
                        <span>Sync</span>
                    </div>
                </div>
                <div class="side">
                    <div class="notification">
                        <div class="notificationGreen" v-if="notificationsGreen == true"></div>
                        <img src="../../assets/images/notificationIcon.png" alt="" @click="notificationsClick">
                        <div class="notification_body" v-if="notificationsActive == true">
                            
                            <div  v-for="notification in notifications" :key="notification.id">
                                <router-link :to="notification.route">
                                    <div class="single_notification" @click="notificationsActive = !notificationsActive">
                                        <p> {{notification.body}} </p>
                                        <div>
                                            <span>{{notification.created_at}}</span>
                                            <p>{{notification.title}}</p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            
                        </div>
                        <div class="notifications_bg" v-if="notificationsActive == true" @click="notificationsClick"></div>
                    </div>
                    <div class="name_container">
                        <h3>{{profile.name}}</h3>
                        <h4>{{profile.role[0]}}</h4>
                    </div>
                    <div class="img_container">
                        <img :src="profile.image" alt="">
                        <div class="active_status"></div>
                    </div>
                    
                </div>
            </div>

            <div class="main_content">
                <div class="new_notification" :class="notificationsSeen == true && 'show_new_notification'" @click="notificationsSeen = false">
                    <div>
                        <router-link :to="newNotificatoin.route">
                            <div class="single_notification">
                                <p> {{newNotificatoin.body}} </p>
                                <div>
                                    <span>{{newNotificatoin.created_at}}</span>
                                    <p>{{newNotificatoin.title}}</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <router-view></router-view>
            </div>
        </div>
        
    </div>
</template>
<script>
import axios from 'axios';
import Request from '../../services/Request';
// import store from "@/store/index.js";

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

Pusher.logToConsole = true;

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '433066e9b20e62b8a8b9',
    forceTLS: true,
    cluster: 'mt1',
});


import notificationSound from '@/assets/images/notificationSound.wav';
export default {
    name: 'StoreAdmin',
    data(){
        return{
            showSideMenu: true,
            productsArrow: true,
            hoverType: '',
            profile: {},
            notifications: [],
            notificationsActive: false,
            notificationsSeen: false,
            notificationsGreen: true,
            store_list_orders: false,
            store_statistics: false,
            store_management: false,
            store_prescriptions: false,
            store_cities: false,
            store_linking_disticts: false,
            store_products: false,
            store_categories: false,
            store_companies: false,
            store_out_of_stock: false,
            store_users: false,
            store_copouns: false,
            store_reports: false,
            store_pannars: false,
            store_Offers: false,
            store_evaluation: false,
            newNotificatoin: {}
        }
    },
    beforeMount(){
        var permissions = JSON.parse(localStorage.getItem("permissions"));

        if(permissions.includes('list orders')){
            this.store_list_orders = true
        } 
        if(permissions.includes('store management')){
            this.store_management = true
        } 

        if(permissions.includes('list products')){
            this.store_products = true
        } 
        if(permissions.includes('list prescriptions')){
            this.store_prescriptions = true
        } 
        if(permissions.includes('list delivery cities')){
            this.store_cities = true
        } 
        if(permissions.includes('list link districts')){
            this.store_linking_disticts = true
        } 
        if(permissions.includes('list products')){
            this.store_products = true
        } 
        if(permissions.includes('list products categories')){
            this.store_categories = true
        } 
        if(permissions.includes('list companies')){
            this.store_companies = true
        } 
        if(permissions.includes('list out of stock products')){
            this.store_out_of_stock = true
        } 
        if(permissions.includes('list store users')){
            this.store_users = true
        } 
        if(permissions.includes('list promo codes')){
            this.store_copouns = true
        } 
        if(permissions.includes('list store reports')){
            this.store_reports = true
        } 
        if(permissions.includes('list banners')){
            this.store_pannars = true
        } 
        if(permissions.includes('list offers')){
            this.store_Offers = true
        } 
        if(permissions.includes('store evaluation')){
            this.store_evaluation = true
        } 
    },
    mounted(){
        
        this.linkUrlCheck();
        this.getProfile();
        this.getNotifications(); 

        window.Echo.channel('admin.order.notification')
        .listen('.admin.order.created', (e) => {
            
            this.newNotificatoin = e;
            this.notificationsSeen = true;
            this.notificationsGreen = false;
            this.getNotifications();
            const audio = new Audio(notificationSound); // Ensure this path is correct
            if(audio){

                audio.play();
            }
            setTimeout(() => {
                this.notificationsSeen = false;
            }, 3000)
        });
    },
    methods: {
        notificationsClick(){
            this.notificationsActive = !this.notificationsActive;
            this.notificationsSeen = false;
            this.notificationsGreen = true;
        },
        changeArrow(){
            this.productsArrow = !this.productsArrow
        },
        linkUrlCheck(){
            if (window.location.href.indexOf("statistics") > -1) {
                this.hoverType = 'statistics'
            } else if(window.location.href.indexOf("products") > -1){
                this.hoverType = 'products'
            } else if(window.location.href.indexOf("users") > -1){
                this.hoverType = 'users'
            } else if(window.location.href.indexOf("categories") > -1){
                this.hoverType = 'categories'
            } else if(window.location.href.indexOf("cities") > -1){
                this.hoverType = 'cities'
            } else if(window.location.href.indexOf("linking-disticts") > -1){
                this.hoverType = 'linking-disticts'
            }  else if(window.location.href.indexOf("requests") > -1){
                this.hoverType = 'requests'
            }  else if(window.location.href.indexOf("prescriptions") > -1){
                this.hoverType = 'prescriptions'
            }  else if(window.location.href.indexOf("copouns") > -1){
                this.hoverType = 'copouns'
            }  else if(window.location.href.indexOf("companies") > -1){
                this.hoverType = 'companies'
            }  else if(window.location.href.indexOf("out-of-stock") > -1){
                this.hoverType = 'out-of-stock'
            }  else if(window.location.href.indexOf("reports") > -1){
                this.hoverType = 'reports'
            }  else if(window.location.href.indexOf("pannars") > -1){
                this.hoverType = 'pannars'
            }  else if(window.location.href.indexOf("offers") > -1){
                this.hoverType = 'offers'
            }  else if(window.location.href.indexOf("comments") > -1){
                this.hoverType = 'comments'
            }  else if(window.location.href.indexOf("availability") > -1){
                this.hoverType = 'availability'
            }
        },
        toggleMenu(){
            this.showSideMenu = !this.showSideMenu
        },
        getProfile(){
            axios.get(`https://app.almujtama.com.sa/admin/profile`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                this.profile = response.data.data
            
            })
        },
        refreshPage() {
            // Reload the current page
            location.reload();
        },

        getNotifications(){
            
            Request.fetch('admin/notifications', '', '')
            .then((response) => {
                this.notifications = response.data.data
                console.log('pusher')
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });

            
        },
    }
}
</script>
<style lang="scss" scoped>
.menuShow{
    width: 314px;
    @media (max-width: 991px) {
        width: 0;
    }
}
.menuHide{
    width: 0;
    @media (max-width: 991px) {
        width: 225px;
        right: 0 !important;
    }
}
.contentShow{
    width: calc(100% - 314px);
    @media (max-width: 991px) {
        width: 100%;
    }
}
.contenthide{
    width: 100%;
    @media (max-width: 991px) {
        width: 100%;
    }
}
.admin_page{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 991px) {
        position: relative;
    }
    .side_menu{
        transition: .3s ease-in-out;
        background-color: #FFF;
        min-height: 100vh;
        @media (max-width: 991px) {
            position: absolute;
            top: 0;
            right: -277px;
            bottom: 0;
            background-color: #FFFF;
            z-index: 99;
            width: 225px;
        }
        @media (max-width: 576px) {
            right: -110%;
            width: 100%;
        }
        .toggleMenu{
            display: flex;
            justify-content: flex-end;
            padding: 20px 0 0 0;
            display: none;
            @media (max-width: 991px) {
                display: flex;
            }
            svg{
                font-size: 35px;
                color: #78A28F; 
            }
        }
        .logo{
            width: 186px;
            margin: 10px auto 40px auto;
            img{
                width: 100%;
            }
        }
        .store_manage{
            width: 164px;
            background-color: #78A28F;
            padding: 10px;
            text-align: center;
            font-size: 16px;
            color: #FFF;
            display: block;
            margin: auto auto 20px auto;
        }

        .links{
            ul{
                li{
                    a{
                        font-size: 16px;
                        color: #444444;
                        display: flex;
                        align-items: center;
                        padding: 15px 20px;
                        -webkit-box-shadow: -1px 6px 4px 0px #EFEFEF;
                        -moz-box-shadow: -1px 6px 4px 0px #EFEFEF;
                        box-shadow: -1px 6px 4px 0px #EFEFEF;
                        font-weight: 600;
                        .img_container{
                            width: 30px;
                            margin-left: 10px;
                            img{
                                width: 25px;
                            }
                        }
                        
                    }
                    .aciveLink{
                        background-color: #D8D253;  
                        color: #FFF;  
                    }
                    ul{
                        li{
                            a{
                                padding: 15px 57px 15px 20px;
                            }
                        }
                    }
                }
            }
            
        }
        .bigScreen{
            @media (max-width: 991px) {
                display: none;
            }
        }
        .smallScreen{
            display: none;
            @media (max-width: 991px) {
                display: block;
            }
        }
    }
    .content{
        transition: .3s ease-in-out;
        padding: 30px;
        background-color: #FAFAFA;
        min-height: 100vh;
        @media (max-width: 576px) {
            padding: 10px;
        }
        .navBar{
            border-radius: 15px;
            background-color: #FFF;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-items: center;
            margin-bottom: 40px;
            .side{
                display: flex;
                align-items: center;
                .menu_icon{
                    cursor: pointer;
                }
                .sync_container{
                    margin-right: 30px;
                    cursor: pointer;
                    img{
                        display: block;
                        margin: auto;
                    }
                    span{
                        display: block;
                        text-align: center;
                        font-size: 11px;
                        font-weight: 600;
                    }
                }
                .img_container{
                    width: 61px;
                    height: 61px;
                    border-radius: 50%;
                    background-color: #EFEFEF;
                    position: relative;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                    .active_status{
                        width: 16px;
                        height: 16px;
                        background-color: #28C66F;
                        border-radius: 50%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
                .name_container{
                    margin-left: 23px;
                    h3{
                        font-size: 16px;
                        font-weight: 600;
                        color: #1E1E1E;
                        margin-bottom: 2px;
                        font-family: flatMedium;
                    }
                    h4{
                        font-size: 14px;
                        color: #656565;
                        margin-bottom: 0;
                    }
                }
                .notification{
                    margin-left: 23px;
                    position: relative;
                    z-index: 10;
                    img{
                        cursor: pointer;
                    }
                    .notification_body{
                        width: 300px;
                        height: 400px;
                        overflow-y: scroll;
                        border-radius: 10px;
                        position: absolute;
                        top: 27px;
                        left: 0;
                        background-color: #FFF;
                        z-index: 99;
                        box-shadow: 0 0 10px #DDD;

                        .single_notification{
                            padding: 10px;
                            border-bottom: 1px solid #DDD;
                            cursor: pointer;
                            &:hover{
                                background-color: #f0f0f0;
                            }
                            p{
                                font-size: 16px;
                                margin-bottom: 5px;
                                font-weight: 600;
                                color: #000;
                            }

                            > div{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-top: 10px;
                                color: #000;
                                p, span{
                                    font-size: 12px;
                                    margin-bottom: 0;
                                    color: #000;
                                }
                            }
                        }
                    }
                    .notifications_bg{
                        position: fixed;
                        z-index: 10;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

.main_content{
    .show_new_notification{
        padding: 10px !important;
        width: auto !important;
    }
    .new_notification{
        position: absolute;
        background-color: #FFF;
        box-shadow: 0 0 10px #DDD;
        bottom: 100px;
        left: 0;
        padding: 10px;
        border-radius: 4px;
        width: 0;
        padding: 0;
        overflow: hidden;
        transition: .3s ease-in-out;
        a{
            .single_notification{
                p{
                    font-size: 20px;
                    font-weight: 600;
                    color: #000;
                }
                > div{
                    display: flex;
                    span, p{
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 0;
                        color: #000;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

.notificationGreen{
    width: 10px;
    height: 10px;
    position: absolute;
    top: 2px;
    right: 1px;
    background-color: #78A28F;
    border-radius: 50%;
}
</style>