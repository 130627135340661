export default {

  // remove this
  storeStatus: 'The store is temporarily under maintenance',
  weareSorry: 'Dear customer, we apologize for temporarily closing our online store for maintenance work.',
  weareSorry2: 'We strive to provide you with the best possible shopping experience, and we need to carry out some planned maintenance to ensure the store continues to operate smoothly and efficiently.',

  language: 'English',
  writeHere : 'write Here',
  Muslim : 'Muslim',
  NonMuslim : 'Non – Muslim',
  single : 'single',
  married : 'married',
  Bachelors : 'Bachelors',
  Diploma : 'Diploma',
  Masters : 'Masters',
  PHD : 'PHD',
  Job1 : '1 Job',
  Job2 : '2 Jobs',
  Job3 : '3 Jobs',
  Job4 : '3 Jobs',
  yes : 'Yes',
  no : 'No',
  Professional : 'Professional',
  Advance : 'Advance',
  Intermediate : 'Intermediate',

  slider:{
    news1: 'We are pleased to announce that - thank God - a new branch of the Community Pharmacy has been opened in Al-Qarniyah District - Turki Bin Madi Street - Jeddah.',
    news2: 'We are pleased to announce that - thank God - a new branch of the Community Pharmacy has been opened in Al-Aziziya neighborhood - Shubra Street - Taif.',
    news3: 'We are pleased to announce that - thank God - a new branch of the Community Pharmacy has been opened in Al-Nadhim District - Khurais Road - Riyadh.',
    news4: 'We are pleased to announce that - thanks to God - a new branch of the Community Pharmacy has been opened in the Al-Quriniyah neighborhood - Mujahid Bin Youssef Street - Jeddah.',
    news5: 'We are pleased to announce that - thank God - a new branch of the Community Pharmacy has been opened in the Al-Quriniyah neighborhood - Al-Laith Branch Road - Jeddah.',
    news6: 'We are pleased to announce that - thanks to God - a new branch of the Community Pharmacy has been opened in Al-Zumrud District - King Saud Road - Jeddah.',
    

    newOpening: 'We happily announce that we have opened our new branch for AL MUJTAMA pharmacies in AL SAFA district- PRINCE MUTAIB BIN ABDUL AZIZ – JEDDAH',
    newOpeningDescription: 'Join us in the new branch and benefit from our distinguished services. Community_pharmacies #Leadership_in_Community_Service',
 
    TheLeadingCommunityMedical:'The Leading Community Medical Company announces the approval of the Board of Directors at its meeting held on 12/04/2023 AD to accept the resignation of Board Member Mr. Hisham Omar Baroum (non-executive) from his position on 12/04/2023 AD, provided that the resignation takes effect from the date of submitting the resignation. On 04/12/2023 AD, the reason for his resignation was due to the expiration of the license of the General Assembly held on 06/10/2022 AD regarding allowing him to participate in the membership of the Board of Directors of the Saudi Anova Healthcare Company.',
    newBranch:'We are pleased to announce that - thank God - a new branch of the Community Pharmacy will be opened in Al-Sanabel neighborhood - Jeddah. Join us in the new branch and benefit from our distinguished services. Community_Pharmacies',
    newBranchTag1:'#For_a_healthy_conscious_society',
    newBranchTag2:'#Leadership_in_community_service',
    TheLeadingCommunityMedicalDescription:'The member expressed his thanks and gratitude to the company’s shareholders and members of the Board for the period he spent as a member of the Board of Directors. The Company’s Board of Directors extends its sincere thanks and appreciation to Mr. Hisham Omar Baroum, wishing him continued success.',

    CommunityLeadingMedical:'Community Leading Medical Company (“the Company”) announces the company’s intention to register and list the company’s shares amounting to (9,500,000) ordinary shares directly in the parallel market “Nomu”, and the listing date will be announced soon.',
    CommunityLeadingMedicalDescription : 'Join us in the new branch and benefit from our distinguished services. Community_pharmacies #Leadership_in_Community_Service ',

    CollegeOfPharmacy: 'College of Pharmacy at King Abdulaziz University in Jeddah. All thanks to the golden sponsor, Community Pharmacy, for their contribution to the success of our event.',
    Community_Pharmacy :'#Community_Pharmacy',
    TheManagementOfTheLeading: 'The management of the leading community medical company honored pharmacist Mohamed Ahmed Mohamed Ali for achieving fourth place according to the performance evaluation in the month of September.',

    TheManagementOfTheLeadingDescription: 'Community Pharmacies wishes him and all partners more success and prosperity    '
  },

  landing: {
    Home: 'Home',
    AboutUs: 'AboutUs',
    Store: 'Store',
    EMagazine: 'E-magazine',
    Jobs: 'Jobs',
    OurBranches: 'Our Branches',
    InvestorRelations: 'Investor relations',

// footer
    Pages: 'Pages',
    Collectwithalmujtamajobs: 'Collect with al-mujtama jobs',
    ContactInformation: 'Contact Information',
    PhoneNumber: 'Phone Number',
    Email: 'E-mail',
    MinistryOfHealthUnifiedNo: 'Ministry Of Health Unified No',
    LicenseNo: 'License No',
    FoodAndDrugsAdminNo: 'Food And Drugs Admin No',
    VigilanceProgramme: 'Vigilance Programme (check the link https://ids.sfda.gov.sa/)',
    ContactUs: 'Contact Us',
  },

  // home page
  home:{
    branchesWidelySpread: 'Branches Widely Spread',
    squareMeterToProvideBestQualityInSupplyAnd: 'Square Meter To Provide Best Quality In Supply And',
    ItemsInYourHands: 'Items In Your Hands',
    homeOurBranches: 'Our Branches',
    AbouttheGroup: 'About the Group',
    AbouttheGroupDescription: '• AL-Mujtama pharmacies is a leading and fully integrated group in providing pharmaceutical services. • Partners of the Ministry of Health in implementing health care programs and the Ministry’s system initiatives for the National Transformation Programs 2020 within the Kingdom’s Vision 2030.',
    AbouttheGroupDescriptionSeconde: '• Partners of the Ministry of Health in coordination with the National Unified Procurement Company (NUPCO) to dispense medical prescriptions (Wasfaty) issued by primary care centers and hospitals affiliated with the Ministry of Health, in addition to hospitals affiliated with medical services in other government sectors such as: The Ministry of Defense, The Ministry of Interior, and The Ministry of Higher Education in the following regions: (Jeddah - Mecca - Taif - Riyadh - Medina - Al-Kharj - King Abdullah Economic City - Yanbu - Rabigh - Jazan - Sabya)',
    LeadershipInCommunityServices:'Leadership In Community Services',
    OurServices:'Our services',
    OurServicesDescription:'A group of AL-Mujtama pharmacies for communications and integrated provision of pharmaceutical services.',
    OnlineCommunity:'Online Community',
    OnlineCommunityEMagazine:'E-Magazine',
    OnlineCommunityOnlineStore:'Online Store',
    OnlineCommunityCustomerService:'Customer Service',
    homeInvestorRelations:'Investor Relations',
    homeInvestorRelationsDescriptiom:'Through the investor relations page, the investor can view the company`s reports and results ',
    RelationshipOfInvestors:'Relationship of investors',
    CollectWithALMujtama:'Collect With AL-Mujtama',
    CollectWithALMujtamaDescription: 'A program that allows AL-Mujtama pharmacies customers to obtain free merchandise equivalent to 3% of their purchases when they reach the amount of 300 SAR (not including value-added tax) from any of its branches, website, or application.',
    RegisterNow:'Register Now',
    TheLoyaltyClientProgram:'The (Loyalty) Client Program',
    TheLoyaltyClientProgramDescription:'A program that allows AL-Mujtama pharmacies customers to obtain free merchandise equivalent to 3% of their purchases when they reach the amount of 300 SAR (not including value-added tax) from any of its branches, website, or application.',
    TheLoyaltyClientProgramDescriptionTwo:'A program that allows AL-Mujtama pharmacies customers to obtain free merchandise equivalent to 3% of their purchases when they reach the amount of 300 SAR (not including value-added tax) from any of its branches, website, or application.',
  },

  // about us
  aboutUs:{
    aboutUs: 'About Us',
    AbouttheGroupDescriptionpoint1: '• AL MUJTAMA pharmacy is a leading group that provides pharmaceutical services.',
    AbouttheGroupDescriptionpoint2: '• Partners with Ministry of health, in providing health care programs and ministry initiatives for national transformation 2020 within the vision of kingdom 2030. ',
    AbouttheGroupDescriptionpoint3: '• Partners with Ministry of health in coordination with national company for consolidated purchase (NOPCO) for writing prescriptions (WASFATY) that are issued from primary care centers and hospitals affiliated to Ministry of health including hospitals that are affiliated to medical services in governmental sectors i.e (Ministry of defense, Ministry of interior, Ministry of education in cities (JEDDAH, MAKKAH, TAIF, RIYADH ,MADINAH, Al KHARJ, KING ABDULLAH ECONOMIC CITY, YANBU, RABIGH, JAZAN,SABYA).  ',
    AbouttheGroupDescriptionpoint4: '• Partners with Ministry of health AL MUJTAMA pharmacies provides prescriptions that are issued online from (SEHATY) application and our unified number (937) through our branches. ',
    AbouttheGroupDescriptionpoint5: '• Partners with Ministry of health and number of institutions i.e (KING ABDUL AZIZ UNIVERSITY, UNIVERSITY OF TAIF) for providing community services and training programs for honorary student`s from Medical college that contributes for medical researchers that develops the pharmaceutical and medical sectors.',
    AbouttheGroupDescriptionpoint6: '• Partners with Ministry of health in providing Seasonal Vaccination and detecting vital signs within campaigns #Know your number# In preparation for implementing the unified file system for all citizens, in coordination with the relevant government agencies. ',
    AbouttheGroupDescriptionpoint7: '• AL MUJTAMA pharmacies under supervision of Ministry of health provides medical care with fully equipped pharmacy that provides service and assistance through telemedicine that provides best health service.',

    OurVision:'Our Vision',
    OurVisionDescription:'leadership in serving the community, for a healthy, conscious society',
    OurMessage:'Our Message',
    OurMessageDescription:'Provide integrated pharmaceutical services to all segments of society.',
    OurMessageDescription2:'Protect society from counterfeit and overpriced products. Our slogan: (The original product at the lowest price).',
    OrganizationalChart:'Organizational Chart',
    OrganizationalChartDescription:'The organizational structure is considered a means or tool to help the company`s management achieve its goals efficiently and effectively, as it explains the organizational units that comprise it within hierarchical levels linked together by links of authority that include instructions, orders, and relationships. The organizational structure enables the company to implement plans, make decisions, and define the roles of each department and its employees to avoid overlap and duplication',
    OrganizationalChartDescription2:'The company`s organization structure copes with governance, transparency and corporation system requirements',
    OnlineCommunity:'Online Community',
    OnlineCommunityTitle:'A pioneering service provided by AL-Mujtama group, aimed to provide pharmaceutical services to achieve the true meaning of community medicine via the Internet through the group’s official website: www.almujtama.com.sa or through its official social media account (@almujtamapharma) on all its available media platforms and the latest technical means to improve pharmaceutical service',
    aboutUsEMagazine:'E-Magazine',
    aboutUsEMagazineDescription:'A magazine concerned with the medical and pharmaceutical health field and presents the latest developments in the health field',
    aboutUsOnlineStore:'Online Store',
    aboutUsOnlineStoreDescription:'Allows its valued customers to shop and purchase through the Online pharmacy on the official website, and the store also offers free delivery service',
    CustomerService:'Customer Service',
    CustomerServiceDescription:'AL-Mujtama pharmacies Group believes that its valued customers are partners in its success and are the main focus of care',
    CommunityContributions:'Community Contributions',
    CommunityContributionsDescription:'AL-Mujtama pharmacies Group has participated in providing many community services to achieve its vision and mission of serving the community. The most important of these contributions are the following',
    CommunityContributionsPoint1:'• Participation in the #Children’s Vaccination Appointment Alert Service # campaign under the supervision of the Health Awareness Department at the Ministry of Health. Where AL-Mujtama pharmacies received a certificate of gratitude and regards from His Excellency the Minister of Health, His Excellency Dr. Tawfiq Al-Rabiah, in appreciation of their efforts in the campaign. ',
    CommunityContributionsPoint2:'• Participation in #ImproveYourHabits# campaign under the supervision of the Health Awareness Department at the Ministry of Health ',
    CommunityContributionsPoint3:'• Participation in the success of the Gulf Oral and Dental Health Week under the slogan (Teeth Are for Health and Beauty) for nine years. ',
    CommunityContributionsPoint4:'• Ministry of Health partners in providing the seasonal influenza vaccination service for a period of 3 years starting in the year 1439 AH. ',
    CommunityContributionsPoint5:'• Training the College of Pharmacy students (internship stage) based on an agreement with both the Ministry of Health and the College of Pharmacy at King Abdulaziz University for a period of 6 years starting from 1439 AH. ',
    CommunityContributionsPoint6:'• Participate in providing training, educational and cooperative services within some schools. ',
    CommunityContributionsPoint7:'• Participate in the graduation ceremony for male and female College of Pharmacy students at King Abdulaziz University for the last 5 years, in addition to sponsoring all the activities held by the College of Pharmacy at King Abdulaziz University. ',
    CommunityContributionsPoint8:'• Operate and manage outpatient pharmacies for a number of Ministry of Health belonging hospitals and more. ',
    CommunityContributionsPoint9:'• Effective contribution in “RSD” program and integrating the group’s system with the Food and Drug Authority.',
    CommunityContributionsPoint10:'• Contribute to the “Vigilance” program and reporting the therapeutic groups that must be available in the community pharmacies, which were not previously available. ',
    CommunityContributionsPoint11:'• Propose alternatives and their sources, which made it possible to increase the availability rate and not rely on specific brand names',
    branchesspreadwidely:'(160) branches spread widely',
    branchesOnService:'(125) branches on service',
    branchesUnderEstablishment:'(35) branches under establishment',
    OurWarehouses:'Our Warehouses',
    OurWarehousesDecription1:'We strive to achieve the highest international standards of safety and quality in our warehouses with the licensing and supervision of the Food and Drug Authority, and the implementation of new systems such as the drug tracking system.',
    OurWarehousesDecription2:'A fleet of transportation means to our branches that meet the required standards in transporting medications, cosmetic products, baby food and other pharmaceutical items.',
    OurWarehousesDecription3:'More than (25,000) items at your disposal.',
    OurWarehousesDecription4:'Our central warehouse is located in the city of Jeddah in the third industrial zone “Modon” with a total area of (21,000) square meters to provide the best quality in logistics, and apply the highest standards of logistic services to the company’s branches.',
    OurWarehousesDecription5:'(21000) square meters of storage space to serve all the tastes of our valued customers.',
    OurWarehousesDecription6:'More than (3000) packages are received and sent daily.',
  },

  investor:{
    INVESOTRSRELATION :'INVESOTRS RELATION',
    INVESOTRSRELATIONDescription :'By clicking on the investor page, the investor can view data and results of the company.',
    AboutTheGroup :'About the Group',
    AboutTheGroupDescription1 :'• AL MUJTAMA pharmacy is a leading group that provides pharmaceutical services.',
    AboutTheGroupDescription2 :'• Partners with Ministry of health, in providing health care programs and ministry initiatives for national transformation 2020 within the vision of kingdom 2030. ',
    AboutTheGroupDescription3 :'• Partners with Ministry of health in coordination with national company for consolidated purchase (NOPCO) for writing prescriptions (WASFATY) that are issued from primary care centers and hospitals affiliated to Ministry of health including hospitals that are affiliated to medical services in governmental sectors i.e (Ministry of defense, Ministry of interior, Ministry of education in cities (JEDDAH, MAKKAH, TAIF, RIYADH ,MADINAH, Al KHARJ, KING ABDULLAH ECONOMIC CITY, YANBU, RABIGH, JAZAN,SABYA). ',
    AboutTheGroupDescription4 :'• Partners with Ministry of health AL MUJTAMA pharmacies provides prescriptions that are issued online from (SEHATY) application and our unified number (937) through our branches. ',
    AboutTheGroupDescription5 :'• Partners with Ministry of health and number of institutions i.e (KING ABDUL AZIZ UNIVERSITY, UNIVERSITY OF TAIF) for providing community services and training programs for honorary student\'s from Medical college that contributes for medical researchers that develops the pharmaceutical and medical sectors. ',
    AboutTheGroupDescription6 :'• Partners with Ministry of health in providing Seasonal Vaccination and detecting vital signs within campaigns #Know your number# In preparation for implementing the unified file system for all citizens, in coordination with the relevant government agencies. ',
    AboutTheGroupDescription7 :'• AL MUJTAMA pharmacies under supervision of Ministry of health provides medical care with fully equipped pharmacy that provides service and assistance through telemedicine that provides best health service.',
    LatestNews :'Latest News',
    ReportsAndResults :'Reports and Results',
    downloadTheFile :'download the file',
    MoneyMarketDisclosure :'Money Market disclosure',
    MoneyMarketDisclosureDescription :'Al Mujtama Leading Medical Company announces the approval of the board of directors at its meeting held on 04/12/2023 to accept the resignation of board member Mr. Hisham Omar Baroum (non-executive member) from his position on 04/12/2023, provided that the resignation is effective from the date of submission of the resignation on 04/12/2023.the reason behind the resignation is due to the expiration of the license of the General Assembly held on 06/10/2022 to allow him to participate in the membership of the board of Directors of Inova Saudi healthcare company.',
    AdvertisementDetails :'Advertisement details',
    KeyIndex :'Key Index',
    KeyPerformanceIndex :'Key performance index',
    
    FirstQuarter :'(First Quarter of 2023)',

    // questions
    quetionsHeadTitle: 'Interview with our CEO and Managing director of AL MUJTAMA LEADING MEDICAL company',
    quetionsTitle: 'Get to know our strategy and our competitive benefits',
    question1 :'1-What is your take on competition in pharmaceutical market and how much is your share?',
    answer1 :'There are around 9 thousand AL MUJTAMA pharmacies around the kingdom and our group has around (125) pharmacies till date. And we are planning to open around more (40) pharmacies till end of 2024. And for the market share there isn’t any specific or official number currently. But what I can say is AL MUJTAMA pharmacies acquires around 8-10 % prescriptions that are given from hospitals and medical centres in kingdom through (WASFATY) application.',
    question2 :'2-What is the strategy of the company in expanding and opening more pharmacies? And what are the targeted areas to expand in future?',
    answer2:'Opening more branches has always been the goal of our group while gradual increase in last period as we will open around 25-30 pharmacies annually. And we are expecting to open soon our main warehouse in a space of (21,000 square meter) that will expand and will be added to our 40 branches annually. And for the targeted areas the geographical distribution is well considered, we are planning to go deep in areas where there are no service available. Although number of AL MUJTAMA pharmacies in kingdom is (1 pharmacy for each 4000 population) which is considered higher than the global average (1 pharmacy for each 8000 population) except still there are places where our services are unavailable. AL MUJTAMA pharmacies have priority in operating and manging pharmacies for hospitals and primary care centres affiliated with Ministry of health within their corridors (3 HOSPITALS + UNIVERSITY HOSPITAL) within its (125 branches) as of date of this interview until the medical prescription is given with in (WASFATY) application that is prescribed by ALMUJTAMA pharmacies – many of it are only references – such as providing pharmaceutical consultations and telemedicine and online store and free delivery have increased in demand for geographical expansion.',
    question3 :'3-What is the geographical distribution?',
    answer3 :'',

    // table
    tableHeader1: 'City',
    tableHeader2: 'Branches',
    tableHeader3: 'Percentage',
    JEDDAH: 'JEDDAH',
    TAIF: 'TAIF',
    MAKKAH: 'MAKKAH',
    RIYADH: 'RIYADH',
    JAZAN: 'JAZAN',
    MADINAH: 'MADINAH',
    RABIGH: 'RABIGH',
    ALKHARJ: 'ALKHARJ',
    YANBU: 'YANBU',
    ONLINESTORE: 'ONLINE STORE',

    question4 :'4-The company owns large chain of pharmacies in different regions of Kingdom, can you tell us in detail about the performance of these pharmacies in terms of revenue, net income, and growth rate?',
    answer4 :'The company achieved revenues estimated at approximately 367 million SAR, as of end of 2022, the company achieved growth rate of 21% revenue and growth in net profit by 144% compared to last year. Further the companies net revenue amounted to approximately 204 million SAR and net profit approximately 11 million SAR as of 30 June 2023. That is growth in revenue by 19% and increase in net profit by 66% compared to last similar financial year.',
    question5 :'5-The company has achieved profit rate of 11.3 million SAR at 1st quarter of 2023. What is your take on this result? What is your expectation for the 2nd quarter?',
    answer5 :'The company has achieved a growth in net profit by 144% in 2022 compared to last similar financial year, because of spending on extra cash flow into the company with the entry of new investors and increase in number of our new branches compared to what it was in previous years before the spending on extra cash flow. Accordingly, growth in revenues and net profits have been achieved during the first half of 2023, as the company’s profits amounted to 11.33 million Saudi riyals as of June 30, 2023. The company is expected to achieve total sales of 430 million Saudi riyals by the end of 2023, and net profits of 24 million Saudi riyals, an increase of 25% over the year 2022.',
    question6 :'6-What is the company\'s vision for the future of KINGDOMs healthcare industry and the challenges and opportunities available in this sector?',
    answer6 :'Since the announcement of the KINGDOMS intention to privatize health care services, the private health sector has taken important steps represented by the entry of investors to establish many hospitals and specialized centres in partnership with international and local experts with extensive experience. In this regard, AL MUJTAMA leading medical company established a warehouse on an area of 21 thousand square meters equipped with the latest storage and dispensing methods to meet the increasing demand for the services of its branches that are being opened and expanding into new geographical areas. AL MUJTAMA Leading medical company also operates external pharmacies For a number of hospitals affiliated with the Ministry of Health and the Ministry of Higher Education in JEDDAH, TAIF, JAZAN, and SABYA, And responsibility of increasing their capacity and absorptive have increased, and training and employing their national health team, and developing future plans as a pioneering company in implementing national transformation programs and Vision 2030, and as the first community pharmacy chains to achieve the principle of integration with hospitals. And government health centres. If we know that the number of Ministry of Health hospitals is 284 and about 2,300 primary care centres, it becomes clear to us that the company’s intention to establish long-term partnerships with the Ministry of Health and other government health sectors is an excellent investment with a promising future vision.',
    question7: '7-What are your expectations for the additional revenues and profitability resulting from the new expansions?',
    answer7: 'As it was stated in the answer to questions No. (4), No. (5) and No. (6), the field of the pharmaceutical sector is a promising field, and through the careful look into the company’s management, which sees a wide scope for horizontal expansion by opening branches in new geographical areas, and expanding and introducing of latest operating systems such as, Accounting and inventory management systems according to international standards and training sales team, It is also placed among its priorities the development and training of the national health team, achieving the nationalization rate required for the profession of “pharmacist,” signing agreements in this regard with a number of pharmacy colleges, and developing appropriate training programs to qualify the national team to work in the field of AL MUJTAMA pharmacies, which contributed to raising the level of performance and was reflected in leaps. Important in sales numbers and increased profitability. In answer to your question, it is expected that by the end of 2024, sales will exceed half a billion Saudi riyals and net profits will exceed (27) million Saudi riyals.',
    question8: '8-Are there any plans to move into the main market?',
    answer8: 'Yes, especially since the company seeks to strengthen its position and leadership in the field of pharmaceutical services, especially in the field of inventory management, supply chains, and safe and rational spending to provide medicine and pharmaceutical preparations to the beneficiary.',
 
    ConnectingwithinvestorsRelation:'Connecting with investors Relation',
    Name:'Name',
    Email:'Email',
    CellNo:'Cell no',
    Subject:'Subject',
    Mail:'Mail',
    Send:'Send',
  },

  medicalJournal: {
    medicalJournalEmagazine : 'E-magazine',
    search : 'Search',
    Category : 'Category',
    SubCategory : 'Sub Category',
    Tags : 'Tags',
  },

  branches:{
    OnTheMap: 'On The Map',
    branchesWorkingHours: 'Working hours',
    AdditionalBranchServices: 'Additional branch services',
  },

  jobForm:{
    success: 'Data has been recorded successfully',
    goTo: 'To return to the home page',
    replace1: 'Welcome to the leading community medical company ',
    replace2: 'to fill out the employment form ',
    replaceLink: 'click here',
    noForm: 'job application not available',
    EMPLOYMENTAPPLICATIONFORM: 'EMPLOYMENT APPLICATION FORM',
    RequiredJOB: 'Required JOB',
    Pharmaceutical: 'Pharmaceutical',
    NonPharmacist: 'Non Pharmacist',
    WriteTheJob: 'Write The Job',
    mentionedInPassport: 'Name (As mentioned in passport',
    DOB: 'D.O.B',
    Address: 'Address',
    IDno: 'I.D no.',
    Nationality: 'Nationality',
    ExpiryDate: 'Expiry Date',
    Religion: 'Religion',
    AttitudeTowardsRecruitment: 'Marital Status',
    finalExemtion: 'Final Exemtion',
    temparayExemtion: 'Temparay Exemtion',
    CompletedService: 'Completed Service',
    Maritalstatus: 'Maritalstatus',
    NoOfChildren: 'No. of children',
    HomeAddress: 'Home Address',
    MobileNo: 'Mobile no.',
    Profession: 'Profession',
    EmailID: 'Email I.D',
    Qualification: 'Qualification',
    SchoolInstitutionUniversity: 'School/ institution/ University',
    YearOfGraduation: 'Year of Graduation',
    Percentage: 'Percentage',
    YearsOfExperience: 'Years of experience (Jobs you have worked)',
    Workplace: 'Workplace',
    TheCityWhereYouWork: 'The city where you work',
    JobTitle: 'Job Title',
    NumberOfYearsOfWork: 'Number of years of work',
    ReasonsForLeavingWork: 'Reasons for leaving work',
    JobRequirements: 'Job requirements',
    CourseName1: 'Course name 1',
    CourseName2: 'Course name 2',
    ThePartyInChargeOfIt: 'The party in charge of it',
    DidYouUploadYourDocuments: 'Did you upload your documents (University Certificate and Experience Certificate) for Dataflow verification ؟ (For Pharmacist job ONLY)',
    DidYouAdmitTo: 'Did you admit to Prometric (Pearson VUE) exam؟ (For Pharmacist job ONLY)',
    DoYouHaveTheSCFHS: 'Do you have the SCFHS Accreditation Certificate ؟ (For Pharmacist job ONLY)',
    DoYouHaveDrivingLicense: 'Do you have driving license',
    AreYouAbleToDrive: 'Are you able to drive',
    EnglishLevelProficiency: 'English level proficiency',
    KindlyAttachedPassport: 'Kindly attached Passport',
    KindlyAttachedCV: 'Kindly attached C.V',
    KindlyattachedBachelorsdegree: 'Kindly attached Bachelor’s degree',
    KindlyAttachedSyndicateOfPharmacy: 'Kindly attached syndicate of pharmacy',
    KindlyAttachedLicenseForPractisingProfession: 'Kindly attached license for practising profession',
    ExperienceCertificate1: 'Experience Certificate 1',
    ExperienceCertificate2: 'Experience Certificate 2',
    ExperienceCertificate3: 'Experience Certificate 3',
    AttachACopyOfYourTranscript: 'Attach a copy of your transcript',
    professionInKSA: 'Kindly attached license for practising profession in KSA',
    TrainingCourses: 'Training courses/ Programmes',
  }

}