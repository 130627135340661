<template>
    <div>
        <HeaderBg :img="img" title="ربط الأحياء" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteLink" 
            messege="هل أنت متأكد من مسح الحي ؟"
        />
        <header class="admin_content_header">
            <div class="filter">
                <select v-model="filters.city_id" @change="filter">
                    <option value="" selected disabled>المدينة</option>
                    <option :value="city.id" v-for="city in cities" :key="city.id">{{city.translation[0].name}}</option>
                </select>
            </div>
            <div class="search">
                <form @submit.prevent="filter">
                    <div class="inputSearch">
                        <input type="text" v-model="filters.name" placeholder="بحث بالإسم">
                        <button class="searchBtn"><b-icon icon="search" aria-hidden="true"></b-icon></button> 
                    </div>
                </form>
                
            </div>
            <router-link to="/store-admin/linking-disticts/link-district"> ربط حي + </router-link>
            
        </header>
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> الفرع </th>
                        <th> المدينة </th>
                        <th> الحي </th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="branchDistrict in branchDistricts" :key="branchDistrict.id">
                        <td>{{branchDistrict.branch.name}}</td>
                        <td>{{branchDistrict.city.name}}</td>
                        <td>{{branchDistrict.district.name}}</td>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="actionsContainer">
                                <div class="options_container">
                                    <img src="../../../assets/images/selectIcon.png" alt="">
                                    <div class="hidden_options">
                                        <button @click="() => editLinkDistrict(branchDistrict.id)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                                        <button @click="() => deleteData(branchDistrict.id)"> <img src="../../../assets/images/delete-text.png" alt=""> حذف </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="branchDistricts.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import Alert from '../../global/Alert/Alert';
import Request from '../../../services/Request';
import store from "@/store/index.js";
export default {
    name: 'LinkingDisticts',
    components: {HeaderBg, RequestSpinner, Alert},
    data(){
        return{
            loadingRequest: true,
            img: require('../../../assets/images/locationLarg.png'),
            branchDistricts: [],
            cities: [],
            deleteID: '',
            alertToggle: false,

            filters:{
                name: null,
                city_id: null,
                limit: store.state.limit
            },
             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getBranchDistrict();
        this.getCities();
    },
    methods:{
        getBranchDistrict(){
            Request.fetch('admin/branchDistrict', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.branchDistricts = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        editLinkDistrict(id){
            this.$router.push(`/store-admin/linking-disticts/link-district/${id}`)
        },
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteLink(){
            Request.delete('admin/branchDistrict',this.deleteID)
            .then( () => {
                this.getBranchDistrict();
            })
        },

        filter(){
            if(this.filters.name == ''){
                this.filters.name = null
            }
            if(this.filters.city == ''){
                this.filters.city = null
            }
            
            this.loadingRequest = true;
            Request.fetch('admin/branchDistrict', this.apiParams , this.filters)
            .then((response) => {
                this.branchDistricts = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        getCities(){
            axios.get(`https://app.almujtama.com.sa/admin/city`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                this.cities = response.data.data
                this.loadingRequest = false;
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getBranchDistrict();
            } else{
                this.apiParams = `?page=${val}`;
                this.getBranchDistrict();
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.saveBtn{
    margin-top: 0;
    display: block;
    &:hover{
        color: #FFF;
    }
}
</style>