<template>
    <div>
        <HeaderBg :img="img" title="كود الخصم" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteCopoun" 
            messege="هل أنت متأكد من مسح الكود ؟"
        />
        <header class="admin_content_header">
            <div></div>
            <!-- <div class="filter">
                <select>
                    <option value="" selected disabled>المدينة</option>
                </select>
                <div class="tabs_filter">
                    <div class="active_tap">الكل</div>
                    <div>الأكثر شراء</div>
                    <div>النقاط</div>
                </div>
            </div>
            <div class="search">
                <input type="text" placeholder="البحث برقم الفرع">
            </div> -->
            <button @click="openAddCoupon"> إضافة كود خصم + </button>
            
        </header>
        <RequestSpinner v-if="loadingRequest == true" />

        <div class="blocksTable" v-if="loadingRequest == false">
            <div class="header">
                <div class="block">
                    <h3>الكود</h3>
                </div>
                <div class="block">
                    <h3>قيمة الخصم</h3>
                </div>
                <div class="block">
                    <h3>نوع الخصم</h3>
                </div>
                <div class="block">
                    <h3>الاستخدام</h3>
                </div>
                <div class="block">
                    <h3>الحالة</h3>
                </div>
                <div class="block">
                    <h3></h3>
                </div>
            </div>
            <div class="body" v-for="copoun in copouns" :key="copoun.id">
                <div class="block">
                    <h4>{{copoun.code}}</h4>
                </div>
                <div class="block">
                    <h4>{{copoun.discount}}</h4>
                </div>
                <div class="block">
                    <h4>{{copoun.discount_type}}</h4>
                </div>
                <div class="block">
                    <h4>{{copoun.usage_count}}</h4>
                </div>
                <div class="block">
                    <h4>{{copoun.status == 1 ? 'مفعل' : 'غير مفعل'}}</h4>
                </div>
                <div class="block actions">
                    <div class="delete" @click="() => deleteData(copoun.id)">
                        <b-icon icon="trash" aria-hidden="true"></b-icon>
                    </div>
                    <div class="edit" @click="() => editCopoun(copoun.id)">
                        <img src="../../../assets/images/editLogo.svg" alt="">
                    </div>
                </div>
            </div>
        </div>


        <div class="add_coupon_container" v-if="addCoupon == true">
            <div class="add_coupon">
                <div class="header">
                    <h3>
                        {{addCouponType == 'add' ? 'اضافة كود خصم جديد' : 'تعديل كود الخصم' }}
                    </h3>
                    <b-icon icon="x" aria-hidden="true" @click="addCoupon = !addCoupon"></b-icon>
                </div>
                <div class="body">
                    <div class="input_container">
                        <label>الكود</label>
                        <input type="text" v-model="newCoupon.code">
                    </div>
                    <div class="input_container">
                        <label>قيمة الخصم</label>
                        <input type="number" v-model="newCoupon.discount">
                    </div>
                    <div class="input_container">
                        <label>نوع الخصم</label>
                        <select v-model="newCoupon.discount_type">
                            <option value="fixed_amount">قيمة ثابتة</option>
                            <option value="percentage">نسبة مئوية</option>
                        </select>
                    </div>
                    <div class="input_container">
                        <label>حد اقصي للخصم النسبة</label>
                        <input type="number" v-model="newCoupon.max_discount">
                    </div>
                    <div class="input_container">
                        <label>مصرح ل</label>
                        <input type="text" v-model="newCoupon.authorized_to">
                    </div>
                    <div class="input_container">
                        <label>عدد مرات الاستخدام</label>
                        <input type="number" v-model="newCoupon.usage_count">
                    </div>
                    <div class="input_container">
                        <label>الحالة</label>
                        <select v-model="newCoupon.status">
                            <option value="1">مفعل</option>
                            <option value="0">غير مفعل</option>
                        </select>
                    </div>
                    <div class="input_container">
                        
                    </div>
                    <div class="input_container w-100">
                        <label>المنتجات</label>
                        <select>
                            <option value="1">منتج</option>
                        </select>
                    </div>
                    <div class="alert alert-danger" role="alert" v-if="ErrorCheck == true">
                        <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                    </div>
                    <div class="action d-flex justify-content-center w-100">
                        <button class="saveBtn" @click="addNewCoupon" v-if="postLoaded == false">
                            {{addCouponType == 'add' ? 'إضافة ' : 'تعديل' }}
                        </button>
                        <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination mt-5" v-if="copouns.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import store from "@/store/index.js";

export default {
    name: 'Copouns',
    components: {HeaderBg, Alert, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            postLoaded: false,
            img: require('../../../assets/images/prec.png'),
            copouns: [],
            deleteID: '',
            alertToggle: false,
            addCoupon: false,
            addCouponType: 'add',
            newCoupon: {
                code : "",
                discount : null,
                discount_type : "",
                max_discount : '',
                authorized_to : "",
                usage_count : '',
                status : '',
                products: [1]
            },
            errors: [],
            ErrorCheck: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getCopouns();
    },
    methods:{
        openAddCoupon(){
            this.addCoupon = true;
            this.addCouponType = 'add';
        },
        addNewCoupon(){
            this.postLoaded = true;
            if(this.addCouponType == 'add'){
                axios.post('https://app.almujtama.com.sa/admin/copoun', this.newCoupon, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.postLoaded = false
                    this.addCoupon = false
                    this.getCopouns();
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;       
                })
            } else {
                axios.put('https://app.almujtama.com.sa/admin/copoun', this.newCoupon, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.postLoaded = false
                    this.addCoupon = false
                    this.getCopouns();
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;       
                })
            }
        },
        getCopouns(){
            Request.fetch('admin/copoun', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.copouns = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        editCopoun(id){
            this.addCoupon = true;
            this.addCouponType = 'edit';
            axios.get(`https://app.almujtama.com.sa/admin/copoun/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                console.log(response, 'mmmmmm')
                this.newCoupon = response.data.data
                this.loadingRequest = false;
            
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteCopoun(){
            Request.delete('admin/copoun',this.deleteID)
            .then( () => {
                this.getCopouns();
            })
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getCopouns();
            } else{
                this.apiParams = `?page=${val}`;
                this.getCopouns();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.blueColor{
    color: #2081FF;
}

.add_coupon_container{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #00000032;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
    
    .add_coupon{
        background-color: #FFF;
        width: 482px;
        border-radius: 10px;
        padding: 30px 40px;
        overflow-y: scroll;
        margin: 50px 0;
        height: 90%;
        @media (max-width: 576px) {
            height: 100%;
        }
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3{
                font-size: 18px;
                color: #769486;
                font-weight: 600;
            }
            svg{
                font-size: 30px;
                cursor: pointer;
            }
        }
        .body{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .input_container{
                width: 48%;
                margin-bottom: 10px;
                @media (max-width: 991px) {
                    width: 100%;
                }
                label{
                    font-size: 16px;
                    font-weight: 600;
                    display: block;
                    margin-bottom: 5px;
                }
                input,
                select{
                    display: block;
                    border: 1px solid #DDD;
                    border-radius: 10px;
                    padding: 5px;
                    width: 100%;
                }
            }
        }
    }
}

.blocksTable{
    width: 100%;
    @media (max-width: 991px) {
        overflow-x: scroll;
    }
    .header,
    .body{
        display: flex;
        justify-content: space-between;
        @media (max-width: 991px) {
            width: 991px;
        }
        .block{
            width: calc(100% / 8);
            text-align: center;
            h3{
                font-size: 15px;
                font-weight: 600;
                color: #78A28F;
            }
            h4{
                font-size: 15px;
                color: #1E1E1E;
                font-weight: 600;
                font-family: flatMedium;
            }
        }
        .actions{
            display: flex;
            align-items: center;
            .delete,
            .edit{
                width: 40px;
                height: 34px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #FF002B;
                cursor: pointer;
                svg{
                    color: #FFF;
                    font-size: 25px;
                }
            }
            .edit{
                background-color: #78A28F;
                margin-right: 10px;
            }
        }
    }
    .header{
        background-color: #FFF;
        margin-bottom: 27px;
        .block{
            padding: 10px;
        }
    }
    .body{
        background-color: #FFF;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #00000029;
        margin-bottom: 16px;
        .block{
            padding: 30px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 1450px) {
                padding: 10px;
            }
        }
        .show{
            width: 40px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #78A28F;
            border-radius: 10px;
            cursor: pointer;
            svg{
                font-size: 30px;
                color: #FFF;
            }
        }
    }
}
</style>