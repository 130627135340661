<template>
    <div>
        <HeaderBg :img="img" title="إضافة خبر" />

        <div class="form_container">
            <form @submit.prevent="addNews">
                <div class="upload_img">
                    <span> صورة الخبر</span>
                    <div class="img_container">
                        <img src="../../../assets/images/replaceImg.svg" alt="" v-if="imgUrl == ''">
                        <img :src="imgUrl" alt="" v-if="imgUrl !== ''">
                        <div class="photo_upload">
                            <div class="upladImg">
                                <div class="upload">
                                    <label for="">
                                        <img src="../../../assets/images/inputFile.svg" alt="">
                                        <input type="file" v-on="{ change: [uploadNewsImg] }">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input_container">
                    <label> اسم الخبر باللغة العربية</label>
                    <input type="text" v-model="news.translation[0].name">
                </div>
                <div class="input_container">
                    <label> اسم الخبر باللغة الإنجليزية</label>
                    <input type="text" v-model="news.translation[1].name">
                </div>
                <div class="input_container">
                    <label>المحتوي باللغة العربية</label>
                    <client-only>
                        <VueEditor  v-model="news.translation[0].content" />
                    </client-only>
                </div>
                <div class="input_container">
                    <label>المحتوي باللغة الإنجليزية</label>
                    <client-only>
                        <VueEditor  v-model="news.translation[1].content" />
                    </client-only>
                </div>
                
                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Request from '../../../services/Request';
import { VueEditor } from "vue2-editor";
export default {
    name :'AddNews',
    components: {HeaderBg, VueEditor},
    data(){
        return{
            img: require('../../../assets/images/locationLarg.png'),
            postLoaded: false,
            imgUrl: '',
            news: {
                image : '',
                translation : [
                    {
                        name : "",
                        content : "",
                        local : "ar"
                    },
                    {
                        name : "",
                        content : "",
                        local : "en"
                    }
                ]
            },
            errors: [],
            ErrorCheck: false,
        }
    },
    mounted(){
        this.getNews();
    },
    methods:{
        uploadNewsImg(e) {
            this.news.image = e.target.files[0];
            this.imgUrl = URL.createObjectURL(e.target.files[0]);
        },
        addNews(){
            this.postLoaded = true;

            const formData = new FormData();
            if(this.news.image !== ''){
                formData.append('image', this.news.image);
            }
            formData.append('translation[0][local]', this.news.translation[0].local);
            formData.append('translation[0][name]', this.news.translation[0].name);
            formData.append('translation[0][content]', this.news.translation[0].content);
            
            formData.append('translation[1][local]', this.news.translation[1].local);
            formData.append('translation[1][name]', this.news.translation[1].name);
            formData.append('translation[1][content]', this.news.translation[1].content);
            

            if(this.$route.params.id !== undefined){
                formData.append('_method', 'PUT');
                axios.put(`https://app.almujtama.com.sa/admin/news/${this.$route.params.id}`, formData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/admin/news')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            } else {
                axios.post('https://app.almujtama.com.sa/admin/news', formData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/admin/news')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                })
            }
        },
        getNews(){
            if(this.$route.params.id !== undefined){
                Request.fetch(`admin/news/${this.$route.params.id}`)
                .then((response) => {
                    this.news.translation[0].name = response.data.data.translation[0].name
                    this.news.translation[0].content = response.data.data.translation[0].content
                    this.imgUrl = response.data.data.image
                    this.news.translation[1].name = response.data.data.translation[1].name
                    this.news.translation[1].content = response.data.data.translation[1].content
                })
                .catch(err => {
                    if(Request.statusIsFaield(err)){
                        this.$router.push('/login')
                        localStorage.removeItem('token')
                    }
                });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.form_container{
    background-color: #FFF;
    border-radius: 10px;
    margin: 40px auto;
    padding: 70px 100px;
    @media (max-width: 576px) {
        width: 97%;
        padding: 30px;
    }
    form{
        > div{
            margin-bottom: 40px;
            label{
                display: block;
                text-align: center;
                font-size: 16px;
                color: #717171;
                font-weight: 600;
                margin-bottom: 10px;
            }
            input{
                border: 1px solid #71717191;
                font-size: 16px;
                padding: 5px 10px;
                border-radius: 10px;
                display: block;
                margin: auto;
                width: 50%;
            }
        }
        button{
            margin: 30px auto 0 auto;   
        }
    }
}
.upload_img{
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            width: 100%;
            span{
                font-size: 16px;
                color: #656565;
                margin-left: 50px;
            }
            .img_container{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #FAFAFA;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                img{
                    width: 100%;
                }
                .photo_upload{
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    img{
                        width: 70%;
                    }
                }
            }
        }
</style>