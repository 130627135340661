<template>
    <div>
        <HeaderBg title="الشركات" />
        <header class="admin_content_header">
            <div></div>
            <!-- <div class="filter">
                <select>
                    <option value="" selected disabled>المدينة</option>
                </select>
                <div class="tabs_filter">
                    <div class="active_tap">الكل</div>
                    <div>الأكثر شراء</div>
                    <div>النقاط</div>
                </div>
            </div>
            <div class="search">
                <input type="text" placeholder="البحث برقم الفرع">
            </div> -->
            
        </header>
        <!-- <RequestSpinner v-if="loadingRequest == true" /> -->

        <div class="blocksTable">
            <div class="header">
                <div class="block">
                    <h3>الصورة</h3>
                </div>
                <div class="block">
                    <h3>الاسم بالعربي</h3>
                </div>
                <div class="block">
                    <h3>الاسم بالانجليزية</h3>
                </div>
                <div class="block">
                    <h3></h3>
                </div>
                <div class="block">
                    <h3></h3>
                </div>
            </div>
            <div class="body">
                <div class="block">
                    <div class="img_container">
                        <img src="../../../assets/images/remove22.png" alt="">
                    </div>
                </div>
                <div class="block">
                    <h4>نيفيا</h4>
                </div>
                <div class="block">
                    <h4>Nivea</h4>
                </div>
                <div class="block">
                    <h4></h4>
                </div>
                <div class="block">
                    <h4></h4>
                </div>
            </div>
        </div>
        <!-- <div class="noData" v-if="products.length < 1">
            <p> لا يوجد منتجات </p>
        </div> -->
    </div>
</template>
<script>
// import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
// import Request from '../../../services/Request';
// import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
export default {
    name: 'Copouns',
    components: {HeaderBg},
    data(){
        return{
            loadingRequest: true,
            products: [],
        }
    },
    mounted(){
        // this.getProducts();
    },
    methods:{
        // getProducts(){
        //     axios.get(`https://app.almujtama.com.sa/admin/product`, {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Access-Control-Allow-Origin': '*',
        //             'Access-Control-Allow-Headers': '*',
        //             'Authorization': 'Bearer '+ localStorage.getItem('token'),
        //         },
        //     })
        //     .then((response) => {
        //         console.log(response, 'mmmmmm')
        //         this.products = response.data.data
        //         this.loadingRequest = false;
            
        //     })
        //     .catch(err => {
        //         if(Request.statusIsFaield(err)){
        //             this.$router.push('/')
        //             localStorage.removeItem('token')
        //         }
        //     });
        // },
    }
}
</script>
<style lang="scss" scoped>
.blueColor{
    color: #2081FF;
}

.blocksTable{
    width: 100%;
    @media (max-width: 991px) {
        overflow-x: scroll;
    }
    .header,
    .body{
        display: flex;
        justify-content: space-between;
        @media (max-width: 991px) {
            width: 991px;
        }
        .block{
            width: calc(100% / 8);
            text-align: center;
            h3{
                font-size: 15px;
                font-weight: 600;
                color: #78A28F;
            }
            h4{
                font-size: 15px;
                color: #1E1E1E;
                font-weight: 600;
                font-family: flatMedium;
            }
        }
    }
    .header{
        background-color: #FFF;
        margin-bottom: 27px;
        .block{
            padding: 10px;
        }
    }
    .body{
        background-color: #FFF;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #00000029;
        margin-bottom: 16px;
        .block{
            padding: 20px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 1450px) {
                padding: 10px;
            }
            .img_container{
                width: 64px;
                height: 64px;
                img{
                    width: 100%;
                }
            }
        }
        .show{
            width: 40px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #78A28F;
            border-radius: 10px;
            cursor: pointer;
            svg{
                font-size: 30px;
                color: #FFF;
            }
        }
    }
}
</style>