<template>
    <div>
        <div class="banner_form_container" v-if="this.$route.params.id == 2">
            <form @submit.prevent="addAdsBanner">
                <div v-for="(ads, index) in adsBanner" :key="index" class="ads_container mb-2">
                    <div class="upload_img">
                        <span>  الصورة :</span>
                        <div class="img_container">
                            <img src="../../../../../assets/images/replaceImg.svg" alt="" v-if="ads.showImage == ''">
                            <img :src="ads.showImage" alt="" v-if="ads.showImage !== ''">
                            <div class="photo_upload">
                                <div class="upladImg">
                                    <div class="upload">
                                        <label for="">
                                            <img src="../../../../../assets/images/inputFile.svg" alt="">
                                            <input type="file" @change="(e) => uploadadsBannerImg(e, index)">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input_container">
                        <label> 1 رابط اللافتة :</label>
                        <input type="text" v-model="ads.url">
                    </div>
                    <button class="cancelBtn" @click="() => deleteAds(index, ads.id)"> مسح اللافتة </button>
                </div>

                <div class="add_new" @click="addAds">
                    <div class="blus_icon">+</div>
                    إضافة لافتة اعلانية اخرى 
                </div>
                

                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import Request from '../../../../../services/Request';
import axios from 'axios';
import store from "@/store/index.js";
export default {
    name: 'AdsBanner',
    data(){
        return{
            errors: [],
            ErrorCheck: false,
            postLoaded: false,
            loadingRequest: false,
            deleteID: '',
            alertToggle: false,
            adsBanner: [],
        }
    },
    mounted(){
this.getAdsBanner();
    },
    methods:{
// ads banner
        addAdsBanner(){
            this.postLoaded = true
            const formData = new FormData();

            if(this.adsBanner !== []){
                this.adsBanner.map((data, index) => {
                    formData.append(`ads[${index}][image]`, this.adsBanner[index].image);
                    formData.append(`ads[${index}][url]`, this.adsBanner[index].url);
                    formData.append(`ads[${index}][id]`, this.adsBanner[index].id);
                })
            }

            formData.append('_method', 'PUT');
            axios.post(`${store.state.domain}/admin/updateAdsBanner`, formData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.$router.push('/store-admin/pannars')
                this.postLoaded = false
            })  
            .catch(err =>  {
                this.ErrorCheck = true;
                this.errors = err.response.data.errors;
                this.postLoaded = false;
            })
        },
        getAdsBanner(){
            axios.get(`${store.state.domain}/admin/adsBanner`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                response.data.data.map(data => {
                    this.adsBanner.push(
                        {
                            image : null,
                            url: data.url,
                            showImage: data.image,
                            uuid: data.uuid,
                            id: data.id
                        }
                    )
                })
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                this.$router.push('/login')
                localStorage.removeItem('token')
            }
            });
        },
        uploadadsBannerImg(e, index){
            this.adsBanner[index].image = e.target.files[0];
            this.adsBanner[index].showImage = URL.createObjectURL(e.target.files[0]);
        },
        
        addAds(){
            this.adsBanner.push(
                {
                    image : null,
                    url: '',
                    showImage: ''
                }
            )
        },
        deleteAds(index, id){
            this.adsBanner.splice(index, 1)

            Request.delete('admin/deleteAdsBanner',id)
            .then( () => {
                console.log('success')
            })
        },
    }
}

</script>

<style lang="scss" scoped>
.upload_img{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    span{
        font-size: 16px;
        color: #656565;
        margin-left: 50px;
    }
    .img_container{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #FAFAFA;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
            width: 100%;
        }
        .photo_upload{
            
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            right: 0;
            img{
                width: 70%;
            }
        }
    }
}
</style>