<template>
    <div>
       <div class="banner_form_container">
            <form @submit.prevent="addMainBannerPage">
                <div class="upload_img">
                    <span>  الصورة :</span>
                    <div class="img_container">
                        <img src="../../../../../assets/images/replaceImg.svg" alt="" v-if="main_banner_page_img_url == ''">
                        <img :src="main_banner_page_img_url" alt="" v-if="main_banner_page_img_url !== ''">
                        <div class="photo_upload">
                            <div class="upladImg">
                                <div class="upload">
                                    <label for="">
                                        <img src="../../../../../assets/images/inputFile.svg" alt="">
                                        <input type="file" v-on="{ change: [uploadMainBannerPageImg] }">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import Request from '../../../../../services/Request';
import axios from 'axios';
import store from "@/store/index.js";
export default {
    name: 'OffersPageBanner',
    data(){
        return{
            errors: [],
            ErrorCheck: false,
            postLoaded: false,
            loadingRequest: false,
            deleteID: '',
            alertToggle: false,

            main_banner_page_img_url: '',
            main_banner_page_image: null,
        }
    },
    mounted(){
      this.getMainBannerPage();
    },
    methods:{
        uploadMainBannerPageImg(e) {
            this.main_banner_page_image = e.target.files[0];
            this.main_banner_page_img_url = URL.createObjectURL(e.target.files[0]);
        },
        getMainBannerPage(){
            axios.get(`${store.state.domain}/admin/offerHeader`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {  
                this.main_banner_page_img_url = response.data.data.image 
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                this.$router.push('/login')
                localStorage.removeItem('token')
            }
            });
        },
        addMainBannerPage(){
            this.postLoaded = true
            const formData = new FormData();
            this.main_banner_page_image !== null && formData.append('image', this.main_banner_page_image);
            formData.append('_method', 'PUT');
            axios.post(`${store.state.domain}/admin/offerHeader/1`, formData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.$router.push('/store-admin/pannars')
                this.postLoaded = false
            })  
            .catch(err =>  {
                this.ErrorCheck = true;
                this.errors = err.response.data.errors;
                this.postLoaded = false;
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.upload_img{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    span{
        font-size: 16px;
        color: #656565;
        margin-left: 50px;
    }
    .img_container{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #FAFAFA;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
            width: 100%;
        }
        .photo_upload{
            
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            right: 0;
            img{
                width: 70%;
            }
        }
    }
}
</style>