<template>
    <div>
        <HeaderBg :img="img" title="المنتجات" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteProduct" 
            messege="هل أنت متأكد من مسح المنتج ؟"
        />
        <header class="admin_content_header">
            <!-- <div></div> -->
            <!-- <div class="filter">
                <select>
                    <option value="" selected disabled>المدينة</option>
                </select>
                <div class="tabs_filter">
                    <div class="active_tap">الكل</div>
                    <div>الأكثر شراء</div>
                    <div>النقاط</div>
                </div>
            </div> -->
            <div class="search">
                <form @submit.prevent="filterProduct">
                    <div class="inputSearch">
                        <input type="text" v-model="filters.name" placeholder="بحث بالإسم">
                        <button class="searchBtn"><b-icon icon="search" aria-hidden="true"></b-icon></button> 
                    </div>
                    <div class="inputSearch">
                        <input type="text" v-model="filters.code" placeholder="بحث بالكود">
                        <button class="searchBtn"><b-icon icon="search" aria-hidden="true"></b-icon></button> 
                    </div>
                </form>
                
            </div>
            <!-- <router-link to="/store-admin/products/add-product"> + إضافة منتج </router-link> -->
            
        </header>
        <RequestSpinner v-if="loadingRequest == true" />

        <div class="blocksTable" v-if="loadingRequest == false">
            <div class="header">
                <div class="block">
                    <h3>صورة المنتج</h3>
                </div>
                <div class="block">
                    <h3>اسم المنتج</h3>
                </div>
                <div class="block">
                    <h3>كود المنتج</h3>
                </div>
                <div class="block">
                    <h3>القسم الرئيسي</h3>
                </div>
                <div class="block">
                    <h3>القسم الفرعي</h3>
                </div>
                <div class="block">
                    <h3>السعر</h3>
                </div>
                <div class="block">
                    <h3>الحالة</h3>
                </div>
                <div class="block">
                    <h3></h3>
                </div>
            </div>
            <div class="body" v-for="product in products" :key="product.id">
                <div class="block">
                    <div class="img_container">
                        <img :src="product.image" alt="">
                    </div>
                </div>
                <div class="block">
                    <h4>{{product.translation[0].name}}</h4>
                </div>
                <div class="block">
                    <h4>{{product.code}}</h4>
                </div>
                <div class="block">
                    <h4>{{product.category}}</h4>
                </div>
                <div class="block">
                    <h4>{{product.subcategory}}</h4>
                </div>
                <div class="block">
                    <h4>{{product.price + " " +'ر.س'}}</h4>
                </div>
                <div class="block">
                    <h4>{{product.active == 1 ? 'معروض' : 'غير معروض'}}</h4>
                </div>
                <div class="block">
                    <router-link :to="`/store-admin/products/show-product/${product.id}/${product.code}`" class="show">
                        <b-icon icon="eye" aria-hidden="true"></b-icon>
                    </router-link>
                    <!-- <div class="options_container">
                        <img src="../../../assets/images/selectIcon.png" alt="">
                        <div class="hidden_options">
                            <button @click="() => editProduct(product.id)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                            <button @click="() => deleteData(product.id)"> <img src="../../../assets/images/delete-text.png" alt="" > حذف </button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="pagination mt-5" v-if="products.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
        <div class="noData" v-if="products.length < 1">
            <p> لا يوجد منتجات </p>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import Request from '../../../services/Request';
import store from "@/store/index.js";
export default {
    name: 'Copouns',
    components: {HeaderBg, Alert, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            img: require('../../../assets/images/products_headTitle.png'),
            products: [],
            deleteID: '',
            alertToggle: false,
            filters:{
                name: null,
                code: null,
                limit: store.state.limit
            },

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getProducts();
    },
    methods:{
        
        getProducts(){
            Request.fetch('admin/product', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.products = response.data.data
                this.loadingRequest = false;
                this.pages = Math.round(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
   
        editProduct(id){
            this.$router.push(`/store-admin/products/add-product/${id}`)
        },
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteProduct(){
            Request.delete('admin/product',this.deleteID)
            .then( () => {
                this.getProducts();
            })
        },

        filterProduct(){
            if(this.filters.name == ''){
                this.filters.name = null
            }
            if(this.filters.code == ''){
                this.filters.code = null
            }
            
            this.loadingRequest = true;
            Request.fetch('admin/product', this.apiParams , this.filters)
            .then((response) => {
                this.products = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getProducts();
            } else{
                this.apiParams = `?page=${val}`;
                this.getProducts();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.blueColor{
    color: #2081FF;
}

.blocksTable{
    width: 100%;
    @media (max-width: 991px) {
        overflow-x: scroll;
    }
    .header,
    .body{
        display: flex;
        justify-content: space-between;
        @media (max-width: 991px) {
            width: 991px;
        }
        .block{
            width: calc(100% / 8);
            text-align: center;
            .img_container{
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
            h3{
                font-size: 15px;
                font-weight: 600;
                color: #78A28F;
            }
            h4{
                font-size: 15px;
                color: #1E1E1E;
                font-weight: 600;
                font-family: flatMedium;
            }
        }
    }
    .header{
        background-color: #FFF;
        margin-bottom: 27px;
        .block{
            padding: 10px;
        }
    }
    .body{
        background-color: #FFF;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #00000029;
        margin-bottom: 16px;
        .block{
            padding: 30px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 1450px) {
                padding: 10px;
            }
        }
        .show{
            width: 40px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #78A28F;
            border-radius: 10px;
            cursor: pointer;
            svg{
                font-size: 30px;
                color: #FFF;
            }
        }
    }
}
</style>