<template>
    <div>
        <HeaderBg title="اللافتات" />
        <main-banner v-if="this.$route.params.id == 1" />
        <ads-banner v-if="this.$route.params.id == 2" />
        <offer-banner  v-if="this.$route.params.id == 3" />
        <offers-page-banner v-if="this.$route.params.id == 4" />
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import MainBanner from './components/mainBanner/MainBanner';
import AdsBanner from './components/adsBanner/AdsBanner.vue';
import OfferBanner from './components/offerBanner/OfferBanner.vue';
import OffersPageBanner from './components/offersPageBanner/OffersPageBanner';
export default {
    name: 'AddPannars',
    components: {HeaderBg, MainBanner, AdsBanner, OfferBanner, OffersPageBanner},
    data(){
        return{            
        }
    },
}
</script>
<style lang="scss" scoped>
      
</style>