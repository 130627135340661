
<template>
    <!-- EmploymentApplications.vue -->
    <div>
        <HeaderBg title="الموشرات الرئيسية" />

        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> الاسم</th>
                        <th> الرقم</th>
                        <th> الوصف</th>
                        <th> </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="indicatorPerformance in indicatorPerformances" :key="indicatorPerformance.id">
                        <td>{{indicatorPerformance.translations[0].title}}</td>
                        <td>{{indicatorPerformance.value}}</td>
                        <td>{{indicatorPerformance.translations[0].description}}</td>
                        <td>
                            <div class="options_container" v-if="edit_key_indicators_permission == true">
                                <img src="../../../assets/images/selectIcon.png" alt="">
                                <div class="hidden_options">
                                    <button @click="() => editPerformance(indicatorPerformance.id)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="indicatorPerformances.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import store from "@/store/index.js";
import HeaderBg from '../../global/HeaderBg/HeaderBg'
import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
export default {
    name:'IndicatorPerformance',
    components: {HeaderBg, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            indicatorPerformances: [],

            // permissions
            edit_key_indicators_permission: false,
             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    beforeMount(){
        var permissions = JSON.parse(localStorage.getItem("permissions"));

        if(permissions.includes('list key indicators')){
            console.log('permission')
        } else {
            this.$router.push('/login')
        }

        if(permissions.includes('update key indicators')) {
            this.edit_key_indicators_permission = true
        } 
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getIndicatorPerformance();
    },
    methods:{
        editPerformance(id){
            this.$router.push(`/admin/indicator-performance/edit/${id}`)
            localStorage.setItem('type', 'IndicatorPerformance')
        },
        getIndicatorPerformance(){
            Request.fetch('admin/indicatorPerformance', this.apiParams , {
                limit: store.state.limit,
                category: 'kpis'
            })
            .then((response) => {
                this.indicatorPerformances = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
         // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getIndicatorPerformance();
            } else{
                this.apiParams = `?page=${val}`;
                this.getIndicatorPerformance();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.filter{
    select{
        background-color: #FFF;
        border: 0;
        width: 89px;
        text-align: center;
        font-size: 20px;
        border-radius: 10px;
        margin-bottom: 20px;
    }
}
.link_to{
    a{
        font-size: 12px;
        color: #656565;
    }
}

</style>