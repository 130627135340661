<template>
    <div>
        <div>   
        <HeaderBg title="التوفر" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteAvailabilities" 
            messege="هل أنت متأكد من مسح هذا ؟"
        />
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> الاسم</th>
                        <th> البريد الإلكتروني</th>
                        <th> الهاتف</th>
                        <th> المنتج</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="availability in availabilities" :key="availability.id">
                        <td>{{availability.name}}</td>
                        <td>{{availability.email}}</td>
                        <td>{{availability.mobile}}</td>
                        <td>{{availability.product.name}}</td>
                        
                        <td>
                            <div class="block actions">
                                <div class="delete" @click="() => deleteData(availability.id)">
                                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="availabilities.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import store from "@/store/index.js";
import Request from '../../../services/Request';
import Alert from '../../global/Alert/Alert';
export default {
    name: 'Availability',
        components: {HeaderBg, RequestSpinner, Alert},
    data(){
        return{
            loadingRequest: true,
            img: require('../../../assets/images/locationLarg.png'),
            availabilities: [],

            deleteID: '',
            alertToggle: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getAvailabilities();
    },
    methods:{
        getAvailabilities(){
            Request.fetch('admin/check-availabilities', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.availabilities = response.data.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteAvailabilities(){
            Request.delete('admin/check-availabilities',this.deleteID)
            .then( () => {
                this.getAvailabilities();
            })
        },

        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getAvailabilities();
            } else{
                this.apiParams = `?page=${val}`;
                this.getAvailabilities();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.saveBtn{
    margin-top: 0;
    display: block;
    &:hover{
        color: #FFF;
    }
}
.actions{
    display: flex;
    align-items: center;
    .delete,
    .edit{
        width: 40px;
        height: 34px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FF002B;
        cursor: pointer;
        svg{
            color: #FFF;
            font-size: 25px;
        }
    }
    .edit{
        background-color: #78A28F;
        margin-right: 10px;
    }
}
</style>