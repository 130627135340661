
<template>
    <!-- EmploymentApplications.vue -->
    <div>
        <HeaderBg title="طلبات التوظيف" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteApp" 
            messege="هل أنت متأكد من مسح الطلب   ؟"
        />
        <header class="admin_content_header">
            <div class="filter">
                <select v-model="religionFilter" @change="(e) => getReligionFilter(e)">
                    <option value="" disabled selected> الديانة </option>
                    <option value="muslim"> مسلم </option>
                    <option value="other"> غير مسلم </option>
                </select>

                <select class="mr-3" @change="(e) => getNationalityFilter(e)" >
                    <option value="" disabled selected>{{$t('jobForm.Nationality')}}</option>
                    <option :value="nationality.id" v-for="nationality in nationalities" :key="nationality.id"> {{nationality.translation[0].name}} </option>
                </select>
            </div>
            <div class="search">
                <form @submit.prevent="getSearchFilter">
                    <div class="inputSearch">
                        <input type="text" v-model="searchFilter" placeholder="البحث برقم الجوال أو الوظيفةأو تاريخ الميلاد">
                        <button class="searchBtn"><b-icon icon="search" aria-hidden="true"></b-icon></button> 
                    </div>
                </form>
                
            </div>
            <div>
                
            </div>
            
        </header>
        
        <div class="option_show">
            <p>استقبال الطلبات</p>
            <label class="switch">
                <input type="checkbox" v-model="isChecked" @change="toggleForm">
                <div class="slider round"></div>
            </label>
        </div>
        <div class="formActions">
            <button class="saveBtn" v-if="export_employment_permission == true" @click="downloadExcel"> تصدير </button>
            <button class="selectAllBtn" @click="selectedAllFunction"> تحديد الكل </button>
            <button class="deleteBtn" v-if="delete_employment_permission == true" @click="deleteData"> حذف <b-icon icon="trash-fill" aria-hidden="true"></b-icon></button>
        </div>
        <div>
            <b-alert variant="success" v-if="succesMessage == true" show> تم تغيير حالة استقبال الطلبات بنجاح </b-alert>
        </div>

        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> </th>
                        <th> رقم الطلب</th>
                        <th> الاسم</th>
                        <th> الهاتف</th>
                        <th> الوظيفة المطلوبة</th>
                        <th> التاريخ</th>
                        <th v-if="show_employment_request == true"> </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="application in applications" :key="application.id">
                        <td><input type="checkbox"  class="selectedAll" :value="application.id" v-model="checkAll" @change="(e) => getDeleteID(e)" checked></td>
                        <td>{{application.id}}</td>
                        <td>{{application.name}}</td>
                        <td>{{application.phone_number}}</td>
                        <td>{{application.job}}</td>
                        <td>{{application.created_at.slice(0, 10)}}</td>
                        <td class="link_to" v-if="show_employment_request == true">
                            <router-link :to="'/admin/employment-applications/job-details/'+application.id"> <img src="../../../assets/images/bin.png11.png" alt=""> معاينة </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
             <div class="noData" v-if="applications.length < 1">
                <p> لا يوجد طلبات </p>
            </div>
        </div>
        <div class="pagination mt-5" v-if="applications.length >= 1 && loadingRequest == false">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import store from "@/store/index.js";
import HeaderBg from '../../global/HeaderBg/HeaderBg'
import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import Alert from '../../global/Alert/Alert';
import axios from 'axios';
export default {
    name:'EmploymentApplications',
    components: {HeaderBg, RequestSpinner, Alert},
    data(){
        return{
            checkAll: [],
            isChecked: false,
            succesMessage: false,
            loadingRequest: true,
            applications: [],
            IdsToDelete: [],
            deleteID: [],
            alertToggle: false,

            nationalities: [],

            // filters
            religionFilter: '',
            searchFilter: '',

            // permissions
            export_employment_permission: false,
            delete_employment_permission: false,
            show_employment_request: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    beforeMount(){
        var permissions = JSON.parse(localStorage.getItem("permissions"));

        if(permissions.includes('list employment request')){
            console.log('permission')
        } else {
            this.$router.push('/login')
        }

        if(permissions.includes('export employment request')){
            this.export_employment_permission = true
        } 
        if(permissions.includes('delete employment request')) {
            this.delete_employment_permission = true
        }
        if(permissions.includes('show employment request')) {
            this.show_employment_request = true
        }
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getApplications();
        this.getSettings();
        this.getNationalities();
    },
    methods:{
        getReligionFilter(e){
            this.loadingRequest = true;
            Request.fetch('admin/employmentRequests', this.apiParams , {
                religion: e.target.value,
                limit: store.state.limit
            })
            .then((response) => {
                this.applications = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        getNationalityFilter(e){
            Request.getFilteredData('admin/employmentRequests', {
                nationality_id: e.target.value,
            })
            .then(res => {
                this.applications = res.data.data
            })
        },
        getSearchFilter(){
            Request.getFilteredData('admin/employmentRequests', {
                search: this.searchFilter,
            })
            .then(res => {
                this.applications = res.data.data
            })
        },
        selectedAllFunction(){
            const checkboxes = document.querySelectorAll('.selectedAll');

            // Loop through each checkbox and set 'checked' property to true
            checkboxes.forEach(checkbox => {
                checkbox.checked = true;
                this.IdsToDelete.push(checkbox.value)
            });
        },
         // delete data
        deleteData(){
            this.deleteID = this.IdsToDelete;
            this.alertToggle = true;
        },
        acceptedDeleteApp(){
            Request.deleteAll('admin/employmentRequest/bulkDelete',this.IdsToDelete)
            .then( () => {
                this.getApplications();
                this.IdsToDelete = []
            })
        },
        getDeleteID(e){
            
            if(this.IdsToDelete.includes(e.target.value)){
                const indexToRemove = this.IdsToDelete.indexOf(e.target.value);
                if (indexToRemove !== -1) {
                    // If found, remove it using splice
                    this.IdsToDelete.splice(indexToRemove, 1);
                    console.log(this.IdsToDelete)
                }
                } else{
                    this.IdsToDelete.push(e.target.value)
                    console.log(this.IdsToDelete)
                }
        },
        toggleForm(){
            console.log('Checkbox is checked:', this.isChecked);
            axios.post('https://app.almujtama.com.sa/admin/settings', {
                receive_employment_request : this.isChecked
            },
            {
                headers:{
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                }
            })
            .then(() => {
                this.succesMessage = true
                setTimeout(() => {
                    this.succesMessage = false
                }, 3000)
            })
        },
        downloadExcel(){
            window.open(`https://app.almujtama.com.sa/admin/employmentRequest/export?token=${localStorage.getItem('token')}`, '_blank');
        },
        getApplications(){
            Request.fetch('admin/employmentRequests', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.applications = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        getSettings() {
            axios.get('https://app.almujtama.com.sa/admin/settings', {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                this.isChecked = response.data.data.receive_employment_request
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        getNationalities(){
            axios.create({
                baseURL: 'https://app.almujtama.com.sa/api',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                    // localization: store.state.localization
                    'locale': localStorage.getItem('lang')
                }
            })
            .get('/nationalities')
            .then(res => {
                this.nationalities = res.data.data
            });
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getApplications();
            } else{
                this.apiParams = `?page=${val}`;
                this.getApplications();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.deleteBtn,
.selectAllBtn{
    border-radius: 10px;
    border: 0;
    font-size: 16px;
    color: #FFF;
    font-weight: 600;
    padding: 8px 0;
    background-color: #DA372E;
    margin-right: 30px;
    width: 100px;
    text-align: center;
}
.selectAllBtn{
    background-color: #78A28F;
}
.filter{
    select{
        background-color: #FFF;
        border: 0;
        width: 89px;
        text-align: center;
        font-size: 20px;
        border-radius: 10px;
    }
}
.formActions{
    display: flex;
    align-items: center;
    .saveBtn{
        margin: 0;
    }
    
}
.option_show{
    display: flex;
    margin-bottom: 30px;
    p{
        margin-left: 20px;
    }
}
.link_to{
    a{
        font-size: 12px;
        color: #0000E0;
        font-weight: 600;
        
        img{
            width: 30px;
        }
    }
}

</style>