<template>
    <div>
        <!-- ArticlesSections.vue -->
        <HeaderBg title="أقسام المقالات" description="أقسام فرعية" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteCourse" 
            messege="هل أنت متأكد من مسح القسم   ؟"
        />
        <header class="admin_content_header">
            <h2></h2>
            <div class="search">
                
            </div>
            <router-link :to="'/admin/articles-sub-sections/'+this.$route.params.id+'/add-articles-sub-sections'"> أضف قسم جديد </router-link>
        </header>
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> الأسم </th>
                        <th> </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="articlesSubSection in articlesSubSections" :key="articlesSubSection.id">
                        <td>{{articlesSubSection.translation[0].name}}</td>
                        <td>
                            <div class="actionsContainer">
                                <div class="options_container">
                                    <img src="../../../assets/images/selectIcon.png" alt="">
                                    <div class="hidden_options">
                                        <button @click="() => editArticleSubCategory(articlesSubSection.id)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                                        <button @click="() => deleteData(articlesSubSection.id)"> <img src="../../../assets/images/delete-text.png" alt=""> حذف </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="articlesSubSections.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import store from "@/store/index.js";

export default {
    name: 'ArticlesSubSections',
    components: {HeaderBg, Alert, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            articlesSubSections: [],
            deleteID: '',
            alertToggle: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getArticlesSubSections();
        localStorage.removeItem('editArticleSubCategory')
    },
    methods:{
        getArticlesSubSections(){
            Request.fetch('admin/magazineSubcategory', this.apiParams , {
                limit: store.state.limit,
                magazine_category_id : this.$route.params.id
            })
            .then((response) => {
                this.articlesSubSections = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        editArticleSubCategory(id){
            this.$router.push(`/admin/articles-sub-sections/${this.$route.params.id}/add-articles-sub-sections/${id}`)
            localStorage.setItem('editArticleSubCategory', 'edit')
        },
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteCourse(){
            Request.delete('admin/magazineSubcategory',this.deleteID)
            .then( () => {
                this.getArticlesSubSections();
            })
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getArticlesSubSections();
            } else{
                this.apiParams = `?page=${val}`;
                this.getArticlesSubSections();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.actionsContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button{
        background-color: #78A28F;
        border: 10px;
        padding: 5px 20px;
        color: #FFF;
        border: 0;
        margin-left: 20px;
        border-radius: 10px;
    }
}
</style>