<template>
    <div>
        <HeaderBg title="التقارير" />

        <div class="statistics_container">
            <div class="statisticsPart">
                <div class="img_container">
                    <img src="../../../assets/images/personGreen.png" alt="">
                </div>
                <div>
                    <h4> {{reports.users}} </h4>
                    <h3>إجمالي عدد المستخدمين الجدد</h3>
                </div>
            </div>
            <div class="statisticsPart">
                <div class="img_container">
                    <img src="../../../assets/images/searchGreen.png" alt="">
                </div>
                <div>
                    <h4> 10 </h4>
                    <h3>عدد المتصفحين النشطين الأن</h3>
                </div>
            </div>
            <div class="statisticsPart">
                <div class="img_container">
                    <img src="../../../assets/images/houseGreen.png" alt="">
                </div>
                <div>
                    <h4> {{reports.branches}} </h4>
                    <h3>إجمالي عدد الصيدليات</h3>
                </div>
            </div>
            <div class="statisticsPart">
                <div class="img_container">
                    <img src="../../../assets/images/house2Green.png" alt="">
                </div>
                <div>
                    <h4> {{reports.products}} </h4>
                    <h3>عدد المنتجات المعروضة</h3>
                </div>
            </div>
        </div>

        <div class="page_title">
            <div class="line"></div>
            <div class="titleImg_container">
                <h3> المنتجات الإكثر مبيعا </h3>
            </div>
            <div class="line"></div>
        </div> 

        <div class="chart" v-if="mostSellingProducts_loading == true">
          <apexchart
            width="700"
            type="bar"
            :options="mostSellingProducts_Options"
            :series="mostSellingProducts_Series"
          ></apexchart>
        </div>

        <div class="page_title">
            <div class="line"></div>
            <div class="titleImg_container">
                <h3> العملاء الأكثر شرائة </h3>
            </div>
            <div class="line"></div>
        </div> 

        <div class="chart" v-if="most_buying_users_loading == true">
          <apexchart
            width="700"
            type="bar"
            :options="most_buying_users_Options"
            :series="most_buying_users_Series"
          ></apexchart>
        </div>

        <div class="page_title">
            <div class="line"></div>
            <div class="titleImg_container">
                <h3> الأوقات الأكثر شرائا </h3>
            </div>
            <div class="line"></div>
        </div> 

        <div class="chart" v-if="most_active_times_loading == true">
          <apexchart
            width="700"
            type="bar"
            :options="most_active_times_Options"
            :series="most_active_times_Series"
          ></apexchart>
        </div>


    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Request from '../../../services/Request';
export default {
    name: 'Reports',
    components: {HeaderBg},
    data(){
        return{
            reports: {},
            mostSellingProducts_loading: false,
            mostSellingProducts_Options: {
                chart: {
                    id: "vuechart-example",
                },
                xaxis: {
                    categories: [],
                },
            },
            mostSellingProducts_Series: [
                {
                    name: "series-1",
                    data: [],
                },
            ],

            // most_buying_users_loading
            most_buying_users_loading: false,
            most_buying_users_Options: {
                chart: {
                    id: "vuechart-example",
                },
                xaxis: {
                    categories: [],
                },
            },
            most_buying_users_Series: [
                {
                    name: "series-1",
                    data: [],
                },
            ],

            // most_active_times
            most_active_times_loading: false,
            most_active_times_Options: {
                chart: {
                    id: "vuechart-example",
                },
                xaxis: {
                    categories: [],
                },
            },
            most_active_times_Series: [
                {
                    name: "series-1",
                    data: [],
                },
            ],
        }
    },
    mounted(){
        this.getReports();
    },
    methods:{
        getReports(){
            Request.fetch('admin/report', '')
            .then(response => {
                console.log(response.data.data)
                this.reports = response.data.data
                response.data.data.most_selling_products.map(data => {
                    this.mostSellingProducts_Options.xaxis.categories.push(data.name)
                    this.mostSellingProducts_Series[0].data.push(data.total)
                })
                this.mostSellingProducts_loading = true

                response.data.data.most_buying_users.map(data => {
                    this.most_buying_users_Options.xaxis.categories.push(data.name)
                    this.most_buying_users_Series[0].data.push(data.total)
                })
                this.most_buying_users_loading = true

                response.data.data.most_active_times.map(data => {
                    this.most_active_times_Options.xaxis.categories.push(data.time)
                    this.most_active_times_Series[0].data.push(data.total)
                })
                this.most_active_times_loading = true
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                }
            });
        },
    }
   
}
</script>
<style lang="scss" scoped>
.statistics_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    .statisticsPart{
        background-color: #FFF;
        border-radius: 20px;
        box-shadow: 0 0 10px #DDD;
        margin-bottom: 30px;
        width: 20%;
        padding: 20px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
            width: 100%;
        }
        .img_container{
            margin-bottom: 20px;
        }
        > div{
            h3{
                font-size: 24px;
                margin-bottom: 20px;
                font-weight: 600;
                text-align: center;
                @media (max-width: 991px) {
                    font-size: 24px;
                }
            }
            h4{
                font-weight: 600;
                font-size: 24px;
                margin-bottom: 0;
                text-align: center;
                @media (max-width: 991px) {
                    font-size: 24px;
                }
            }
        }
    }
}
.page_title{
    display: flex;
    align-items: center;
    margin: 20px 0 40px 0;
    .line{
        height: 1px;
        background-color: #515151;
        width: 100%;
    }
    .titleImg_container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 60px;
        h3{
            font-size: 30px;
            font-weight: 900;
            color: #515151;
            white-space: nowrap;
        }
        img{
            margin-left: 20px;
        }
    }
}

.chart{
    display: flex;
    justify-content: center;
    text{
        transform: rotate(0) !important;
    }
    *{
        direction: ltr !important;
    }
}
</style>