<template>
    <div>
        <HeaderBg :img="img" title="مدن التوصيل" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteDistrict" 
            messege="هل أنت متأكد من مسح المدينة ؟"
        />

        <header class="admin_content_header">
            <div class="filter">
            </div>
            <div class="search">
                <input type="text" placeholder="البحث برقم الفرع">
            </div>
            <router-link :to="'/store-admin/cities/district/'+ this.$route.params.id + '/add-district/add'"> + إضافة حي </router-link>
            
        </header>

        <div class="main_table">
            <table width="100%">
                <thead>
                    <tr>
                        <th> المدينة</th>
                        <th> اسم الحي</th>
                        <th> المخزن</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="district in districts" :key="district.id">
                        <td>{{district.city.name}}</td>
                        <td>{{district.translation[0].name}}</td>
                        <td>PHA-132</td>
                        <td></td>
                        <td></td>
                        
                        <td>
                            <div class="block actions">
                                <div class="delete" @click="() => deleteData(district.id)">
                                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                                </div>
                                <div class="edit" @click="() => editDistrict(district.id)">
                                    <img src="../../../assets/images/editLogo.svg" alt="">
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="districts.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import store from "@/store/index.js";
import Request from '../../../services/Request';
import Alert from '../../global/Alert/Alert';

export default {
    name: 'District',
    components: {HeaderBg, Alert},
    data(){
        return{
            img: require('../../../assets/images/locationLarg.png'),
            districts: [],

            deleteID: '',
            alertToggle: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getDistrict();
    },
    methods:{
        getDistrict(){
            Request.fetch('admin/district', this.apiParams , {
                limit: store.state.limit,
                city_id: this.$route.params.id
            })
            .then((response) => {
                this.districts = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        editDistrict(cityId){
            this.$router.push(`/store-admin/cities/district/${this.$route.params.id}/add-district/add/${cityId}`)
        },
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteDistrict(){
            Request.delete('admin/district',this.deleteID)
            .then( () => {
                this.getDistrict();
            })
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getDistrict();
            } else{
                this.apiParams = `?page=${val}`;
                this.getDistrict();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.saveBtn{
    margin-top: 0;
}
.actions{
    display: flex;
    align-items: center;
    .delete,
    .edit{
        width: 40px;
        height: 34px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FF002B;
        cursor: pointer;
        svg{
            color: #FFF;
            font-size: 25px;
        }
    }
    .edit{
        background-color: #78A28F;
        margin-right: 10px;
    }
}
</style>