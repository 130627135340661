<template>
    <div>
        <HeaderBg :img="img" title="المستخدمين" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteUser" 
            messege="هل أنت متأكد من مسح المستخدم ؟"
        />
        <header class="admin_content_header">
            <div class="tabs_filter">
                <div @click="() => handelActiveFilter('all')" :class=" activeFilter == 'all' ? 'active_tap' : ''">الكل</div>
                <div @click="() => handelActiveFilter('mostBuying')" :class=" activeFilter == 'mostBuying' ? 'active_tap' : ''">الأكثر شراء</div>
            </div>

            <div class="search">
                <form @submit.prevent="filter">
                    <div class="inputSearch">
                        <input type="text" v-model="filters.search" placeholder=" بحث بالإسم او بالبريد الإلكتروني">
                        <button class="searchBtn"><b-icon icon="search" aria-hidden="true"></b-icon></button> 
                    </div>
                </form>
                
            </div>

            <router-link to="/store-admin/users/add-user"> + إضافة مستخدم </router-link>            
        </header>

        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> الصورة</th>
                        <th> الاسم</th>
                        <th> رقم الجوال</th>
                        <th> البريد</th>
                        <th> الشراء</th>
                        <th> النقاط </th>
                        <th>اخر دخول</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in users" :key="user.id">
                        <td> 
                            <div class="img_container">
                                <img :src="user.image" alt="">
                            </div>
                        </td>
                        <td> {{user.name}} </td>
                        <td> {{user.mobile}} </td>
                        <td> {{user.email}} </td>
                        <td>{{user.buying_amount}} ر.س</td>
                        <td class="blueColor">مشاهدة ......</td>
                        <td>2023-06-24</td>
                        <td>
                            <div class="options_container">
                                <img src="../../../assets/images/selectIcon.png" alt="">
                                <div class="hidden_options">
                                    <button @click="() => editUser(user.id)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                                    <button @click="() => deleteData(user.id)"> <img src="../../../assets/images/delete-text.png" alt="" > حذف </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="users.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import store from "@/store/index.js";
export default {
    name: 'Users',
    components: {HeaderBg, Alert, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            img: require('../../../assets/images/GroupWhite.png'),
            users: [],
            deleteID: '',
            alertToggle: false,

            // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,

            activeFilter: 'all',
            filters:{
                search: null,
                most_buying: 0,
                limit: store.state.limit
            },
        }
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        this.getUsers();
    },
    methods:{
        getUsers(){
            Request.fetch('admin/user', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.users = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        editUser(id){
            this.$router.push(`/store-admin/users/add-user/${id}`)
        },
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteUser(){
            Request.delete('admin/user',this.deleteID)
            .then( () => {
                this.getUsers();
            })
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },

         filter(){
            if(this.filters.search == ''){
                this.filters.search = null
            }
            if(this.filters.city == ''){
                this.filters.city = null
            }
            
            this.loadingRequest = true;
            Request.fetch('admin/user', this.apiParams , this.filters)
            .then((response) => {
                this.users = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },

        handelActiveFilter(event){
            this.activeFilter = event
            if(event == 'all'){
                this.filters.most_buying = 0
            } else {
                this.filters.most_buying = 1
            }
            this.filter();
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getUsers();
            } else{
                this.apiParams = `?page=${val}`;
                this.getUsers();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.blueColor{
    color: #2081FF;
}
.img_container{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    img{
        width: 100%;
    }
}
.tabs_filter{
    display: flex;
    border-radius: 10px;
    background-color: #FFF;
    margin: 0 20px;
    > div{
        padding: 5px 10px;
        font-size: 16px;
        color: #000;
        border-radius: 10px;
        cursor: pointer;
    }
    .active_tap{
        background-color: #D8D253;
        color: #FFF;
    }
}
</style>