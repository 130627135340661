<template>
    <div>
        <!-- Main.vue -->
        <div class="statistics_numbers_container">
            <div class="statistics_number">
                <div class="img_container">
                    <img src="../../../assets/images/statistcs3.png" alt="">
                </div>
                <div class="info">
                    <span>عدد الزائرين لصفحة التوظيف</span>
                    <h3> <span>الاجمالي</span> {{visits.total_employment_page_visits}} </h3>
                    <div>
                        <span>اليومي</span>
                        {{visits.daily_employment_page_visits}}
                    </div>
                    
                </div>
            </div>
            <div class="statistics_number">
                <div class="img_container">
                    <img src="../../../assets/images/statistcs2.png" alt="">
                </div>
                <div class="info">
                    <span>عدد الزائرين لعلاقات المستثمرين</span>
                    <h3> <span>الاجمالي</span> {{visits.total_investor_relations_page_visits}} </h3>
                    <div>
                        <span>اليومي</span>
                        {{visits.daily_investor_relations_page_visits}}
                    </div>
                </div>
            </div>
            <div class="statistics_number">
                <div class="img_container">
                    <img src="../../../assets/images/statistcs1.png" alt="">
                </div>
                <div class="info">
                    <span>عدد الزائرين للرئيسية</span>
                    <h3> <span>الاجمالي</span> {{visits.total_main_page_visits}} </h3>
                    <div>
                        <span>اليومي</span>
                        {{visits.daily_main_page_visits}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Request from '../../../services/Request';
export default {
    name: 'Main',
    data(){
        return{
            visits : {}
        }
    },
    mounted(){
        this.getVisits();
    },
    methods:{
        getVisits() {
            axios.get('https://app.almujtama.com.sa/admin/visitStatistics', {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                }
            })
            .then((response) => {
                console.log(response.data.data)
                this.visits  = response.data.data
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.statistics_numbers_container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    .statistics_number{
        width: 30%;
        padding: 20px;
        background-color: #FFF;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 1200px) {
            width: 33%;
        }
        @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 20px;
        }
        .info{
            text-align: center;
            span{
                font-size: 16px;
                color: #656565;
                font-weight: 800;
            }
            h3{
                font-size: 30px;
                font-weight: 700;
            }
            > div{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                color: #28C66F;
                font-weight: 800;
            }
            .redColor{
                color: #EC5453;
            }
            .greenColor{
                color: #28C66F;
            }
        }
    }
}
</style>