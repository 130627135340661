<template>
    <div>
        <div class="banner_form_container" v-if="this.$route.params.id == 1">
            <form @submit.prevent="addMainBanner">
                <div v-for="(main, index) in main_banner" :key="index" class="mainBannerContainer">
                    <div class="upload_img">
                        <span>  الصورة :</span>
                        <div class="img_container">
                            <img src="../../../../../assets/images/replaceImg.svg" alt="" v-if="main.showImage == ''">
                            <img :src="main.showImage" alt="" v-if="main.showImage !== ''">
                            <div class="photo_upload">
                                <div class="upladImg">
                                    <div class="upload">
                                        <label for="">
                                            <img src="../../../../../assets/images/inputFile.svg" alt="">
                                            <input type="file" @change="(e) => uploadMainBannerImg(e, index)">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input_container">
                        <label>  العنوان باللغة العربية :</label>
                        <input type="text" v-model="main.translation[0].name">
                    </div>
                    <div class="input_container mt-3">
                        <label>  العنوان باللغة الإنجليزية :</label>
                        <input type="text" v-model="main.translation[1].name">
                    </div>
                    <div class="input_container mt-3">
                        <label>  رابط اللافتة :</label>
                        <input type="text" v-model="main.url">
                    </div>
                    <div class="cancelBtn" @click="() => deleteMainBannerImage(index, main.id)"> مسح اللافتة </div>
                </div>

                <div class="add_new" @click="addMainBannerImage">
                    <div class="blus_icon">+</div>
                    إضافة لافتة اعلانية اخرى 
                </div>
                

                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import Request from '../../../../../services/Request';
import axios from 'axios';
import store from "@/store/index.js";
export default {
    name: 'MainBanner',
    data(){
        return{
            errors: [],
            ErrorCheck: false,
            postLoaded: false,
            loadingRequest: false,
            deleteID: '',
            alertToggle: false,
            main_banner:[],
        }
    },
    mounted(){
      this.getMainBanner();
    },
    methods:{
        // main banner
        uploadMainBannerImg(e, index) {
            this.main_banner[index].image = e.target.files[0];
            this.main_banner[index].showImage = URL.createObjectURL(e.target.files[0]);
        },
        addMainBannerImage(){
            this.main_banner.push(
                {
                    translation : [
                        {
                            name : "",
                            local : "ar"
                        },
                        {
                            name : "",
                            local : "en"
                        },
                    ],
                    image: null,
                    url: '',
                    showImage: ''
                },
            )
        },
        deleteMainBannerImage(index){
            this.main_banner.splice(index, 1)

            // Request.delete('admin/deleteAdsBanner',id)
            // .then( () => {
            //     console.log('success')
            // })
        },
        addMainBanner(){
            this.postLoaded = true
            const formData = new FormData();

            if(this.main_banner !== []){
                this.main_banner.map((data, index) => {
                    formData.append(`mainBanners[${index}][translation][0][name]`, data.translation[0].name);
                    formData.append(`mainBanners[${index}][translation][0][local]`, data.translation[0].local);
                    formData.append(`mainBanners[${index}][translation][1][local]`, data.translation[1].local);
                    formData.append(`mainBanners[${index}][translation][1][name]`, data.translation[1].name);
                    formData.append(`mainBanners[${index}][id]`, data.id);
                    if(data.image){
                        formData.append(`mainBanners[${index}][image]`, data.image);
                    }
                    formData.append(`mainBanners[${index}][url]`, data.url);
                })
            }
            formData.append('_method', 'PUT');
            
            axios.post(`${store.state.domain}/admin/updateMainBanner`, formData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( () => {
                this.$router.push('/store-admin/pannars')
                this.postLoaded = false
            })  
            .catch(err =>  {
                this.ErrorCheck = true;
                this.errors = err.response.data.errors;
                this.postLoaded = false;
            })
        },
        getMainBanner(){
            axios.get(`${store.state.domain}/admin/mainBanner`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                response.data.data.map(data => {
                    this.main_banner.push(
                        {
                            image : null,
                            url: data.url,
                            translation:[
                                {
                                    name: data.translation[0].name,
                                    local: 'ar'                
                                },
                                {
                                    name: data.translation[1].name,
                                    local: 'en'
                                },
                            ],
                            showImage: data.image,
                            uuid: data.uuid,
                            id: data.id
                        }
                    )
                })
            })
            .catch((err) => {
                if(Request.statusIsFaield(err)){
                this.$router.push('/login')
                localStorage.removeItem('token')
            }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.upload_img{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    span{
        font-size: 16px;
        color: #656565;
        margin-left: 50px;
    }
    .img_container{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #FAFAFA;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
            width: 100%;
        }
        .photo_upload{
            
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            right: 0;
            img{
                width: 70%;
            }
        }
    }
}
.mainBannerContainer{
    border-bottom: 1px solid #DDD;
    margin-bottom: 40px;
    padding-bottom: 30px;
}
</style>