<template>
    <div>
        <HeaderBg title="المنتجات" />

        <div class="product_details_container" >
            <div class="tabs_container">
                <h3 @click="content = 'productDetails'" :class="content == 'productDetails' ? 'activeTab' : ''">معلومات المنتج</h3>
                <h3 @click="content = 'inventory'" :class="content == 'inventory' ? 'activeTab' : ''">المخزون</h3>
                <h3 @click="content = 'offers'" :class="content == 'offers' ? 'activeTab' : ''">العروض</h3>
            </div>
            <div class="product_info_container" v-if="content == 'productDetails'">
                <div class="product_imgs">
                    <div class="main_img">
                        <h3>الصورة الرئيسية</h3>
                        <img :src="product.image" alt="">
                    </div>
                    <!-- <div class="category_imgs">
                        <h3>صور الالبوم</h3>
                        <img src="" alt="">
                        <img src="" alt="">
                    </div> -->
                </div>

                <div class="form_container">
                    <div class="input_container">
                        <label>العنوان بالعربي</label>
                        <div> {{product.translation[0].name}} </div>
                    </div>
                    <div class="input_container">
                        <label>رقم المنتج</label>
                        <div> {{product.id}} </div>
                    </div>
                    <div class="input_container">
                        <label>المقاسات</label>
                        <div></div>
                    </div>
                    <div class="input_container">
                        <label>العنوان بالانجليزية</label>
                        <div></div>
                    </div>
                    <div class="input_container">
                        <label>رقم الموقع</label>
                        <div></div>
                    </div>
                    <div class="input_container">
                        <label>السعر</label>
                        <div> {{product.price}} </div>
                    </div>
                    <div class="input_container">
                        <label>عرض المنتج فى المتجر</label>
                        <label class="switch">
                            <input type="checkbox" v-model="product_active" @change="updateActiveProduct">
                            <div class="slider round"></div>
                        </label>
                    </div>
                    <b-alert class="w-100" variant="success" show v-if="productActiveLoading == true">تم التعديل بنحاح</b-alert>
                    <!-- <div class="input_container">
                        <label>المنتج له وصفة طبية</label>
                        <label class="switch">
                            <input type="checkbox"  value="true">
                            <div class="slider round"></div>
                        </label>
                    </div> -->
                    <div class="input_container">
                        
                        
                    </div>
                </div>
                <div class="form_container">
                    <div class="input_container">
                        <label>الشركة</label>
                        <div></div>
                    </div>
                    <div class="input_container">
                        <label>القسم الرئيسي</label>
                        <div> {{product.category}} </div>
                    </div>
                    <div class="input_container">
                        <label>القسم الفرعي</label>
                        <div> {{product.subcategory}} </div>
                    </div>
                </div>
                <div class="form_container textAreaContainer">
                    <div class="input_container">
                        <label>المحتوى</label>
                        <textarea></textarea>
                    </div>
                    <div class="input_container">
                        <label>التفاعلات</label>
                        <textarea></textarea>
                    </div>
                    <div class="input_container">
                        <label>الاثار الجانبية</label>
                        <textarea></textarea>
                    </div>
                </div>
            </div>

            <div class="inventory" v-if="content == 'inventory'">
                <div class="totalCount">
                    <h3>اجمالي الكمية المتوفرة</h3>
                    <div class="number">
                        {{stock.count}}
                    </div>
                </div>
                <div class="totalCount inlineCount">
                    <div class="wrapCount" v-for="product in stock.data" :key="product.id">
                        <h3>{{product.Store_No}}</h3>
                        <div class="number">
                            {{product.ActualStock}}
                        </div>
                    </div>
                </div>
                <!-- <div class="pagination mt-5" v-if="stock.data.length >= 1">
                    <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
                </div> -->
                <div class="noData" v-if="stock.length < 1">
                    <p> لا يوجد مخزون </p>
                </div>
            </div>

            <div class="offers"  v-if="content == 'offers'">
                <div class="header">
                    <h3>الخصم</h3>
                    <div class="edit">
                        <img src="../../../assets/images/edit-text.png" alt="">
                    </div>
                </div>
                <div class=" inlineCount">
                    <div class="wrapCount">
                        <h3>PHA-132</h3>
                        <div class="number">
                            8
                        </div>
                    </div>
                    <div class="wrapCount">
                        <h3>PHA-132</h3>
                        <div class="number">
                            8
                        </div>
                    </div>
                    <div class="wrapCount">
                        <h3>PHA-132</h3>
                        <div class="number">
                            8
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Request from '../../../services/Request';
import axios from 'axios';
// import store from "@/store/index.js";
export default {
    name: 'ShowProduct',
    components: {HeaderBg},
    data(){
        return{
            content: 'productDetails',
            loadingRequest: false,
            productActiveLoading: false,
            product: {},
            stock: {},

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
            product_active: false
        }
    },
    mounted(){
        this.getProduct()
        this.getStock()
    },
    methods:{
        getProduct(){
            Request.get(`admin/product/${this.$route.params.id}`)
            .then((response) => {
                this.product = response.data.data
                this.loadingRequest = false;
                this.product_active = response.data.data.active
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        getStock(){
            Request.fetch(`admin/stock/${this.$route.params.code}`, '', '')
            .then((response) => {
                this.stock = response.data
                this.loadingRequest = false;
                // this.pages = Math.round(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
        updateActiveProduct(){
            this.product.active == 1 ? this.productActiveLoading = 0 : this.productActiveLoading = 1;
            this.postLoaded = true
            const formData = new FormData();
            formData.append('active', this.productActiveLoading);
            formData.append('_method', 'PUT');
            formData.append('subcategory_id', this.product.subcategory_id);
            axios.post(`https://app.almujtama.com.sa/admin/product/${this.$route.params.id}`, formData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            .then( res => {
                this.productActiveLoading = true
                setTimeout(() => {
                    this.productActiveLoading = false
                }, 2000)
                console.log(res)
                this.postLoaded = false
            })  
            .catch(err =>  {
                this.errors = err.response.data.errors;
                this.ErrorCheck = true;
                this.postLoaded = false;
            })
        }
    },
     watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getStock();
            } else{
                this.apiParams = `?page=${val}`;
                this.getStock();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.activeTab{
    color: #78A28F !important;
}
.product_details_container{
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    @media (max-width: 1450px) {
        padding: 0;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
    .tabs_container{
        background-color: #FFF;
        border-radius: 10px;
        padding: 30px 30px 60px 30px;
        width: 257px;
        height: 257px;
        @media (max-width: 768px) {
            height: unset;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            margin-bottom: 40px;
        }
        h3{
            font-size: 18px;
            font-weight: 600;
            color: #707070;
            margin-bottom: 15px;
            cursor: pointer;
            transition: .3s ease-in-out;
            &:hover{
                color: #D8D253;
            }
        }
    }

    .product_info_container{
        
        width: calc(100% - 300px);
        @media (max-width: 768px) {
            width: 100%;
        }
        .product_imgs{
            display: flex;
            background-color: #FFF;
            border-radius: 10px;
            padding: 30px;
            margin-bottom: 16px;
            box-shadow: 0px 3px 6px #00000029;
            .main_img{
                margin-left: 100px;
                h3{
                    font-size: 16px;
                    color: #656565;
                    font-weight: 600;
                    margin-bottom: 9px;
                }
                img{
                    width: 110px;
                }
            }
            .category_imgs{
                h3{
                    font-size: 16px;
                    color: #656565;
                    font-weight: 600;
                    margin-bottom: 9px;
                }
                img{
                    width: 11px;
                    margin-left: 20px;
                }
            }
        }

        .form_container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background-color: #FFF;
            padding: 20px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;      
            margin-bottom: 11px;  
            .input_container{
                margin-bottom: 20px;
                width: 30%;
                @media (max-width: 1200px) {
                    width: 45%;
                }
                @media (max-width: 768px) {
                    width: 100%;
                }
                label{
                    display: block;
                    font-size: 14px;
                    font-weight: 600;
                    color: #656565;
                    margin-bottom: 10px;
                }
                >input, select, textarea, >div{
                    display: block;
                    width: 100%;
                    font-size: 16px;
                    color: #656565;
                    border: 1px solid #C5C5C5;
                    border-radius: 10px;
                    padding: 10px;
                    min-height: 46px;
                }
            }
        }
        .textAreaContainer{
            .input_container{
                width: 100%;
            }
        }
    }

    .inventory{
        width: calc(100% - 300px);
        @media (max-width: 768px) {
            width: 100%;
        }
        .totalCount{
            display: flex;
            align-items: center;
            background-color: #FFF;
            border-radius: 10px;
            padding: 30px;
            margin-bottom: 16px;
            box-shadow: 0px 3px 6px #00000029;
            h3{
                font-size: 18px;
                font-weight: 600;
                margin-left: 10px;
                color: #78A28F;
            }
            .number{
                border-radius: 10px;
                border: 1px solid #C5C5C5;
                padding: 10px 20px;
                font-size: 18px;
                color: #707070;
                font-weight: 600;
                width: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .inlineCount{
            flex-direction: column;
            align-items: unset;
            justify-content: unset;
            h3{
                color: #707070;
                margin-left: 30px;
            }
            .wrapCount{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
        }
    }
    .offers{
        width: calc(100% - 300px);
        background-color: #FFF;
        border-radius: 10px;
        padding: 30px;
        margin-bottom: 16px;
        box-shadow: 0px 3px 6px #00000029;
        @media (max-width: 768px) {
            width: 100%;
        }
        
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3{
                font-size: 18px;
                font-weight: 600;
                color: #78A28F;
            }
            .edit{
                background-color: #78A28F;
                width: 40px;
                height: 34px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                svg{
                    font-size: 30px;
                    color: #FFF;
                }
            }
        }
        .inlineCount{
            flex-direction: column;
            align-items: unset;
            justify-content: unset;
            h3{
                color: #707070;
                margin-left: 30px;
                font-weight: 900;
            }
            .number{
                border-radius: 10px;
                border: 1px solid #C5C5C5;
                padding: 10px 20px;
                font-size: 18px;
                color: #707070;
                font-weight: 600;
                width: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .wrapCount{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
        }
    }
}
</style>