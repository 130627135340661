<template>
    <div>
        <HeaderBg title="تقييمات المتجر" />
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="request_table_container" v-if="loadingRequest == false">
            <div class="detailsTable">
                <div class="table_header">
                    <div class="block">
                        الاسم
                    </div>
                    <div class="block">
                        البريد
                    </div>
                    <div class="block">
                        تاريخ التعليق
                    </div>
                    <div class="block">
                        التعليق
                    </div>
                    <div class="block">

                    </div>
                    <div class="block">
                        العرض       
                    </div>
                </div>
                <div class="table_row" v-for="(comment, index) in comments" :key="index">
                    <div class="block">
                        <span> {{comment.name}} </span>
                    </div>
                    <div class="block">
                        <span> {{comment.email}} </span>
                    </div>
                    <div class="block">
                        <span> {{comment.date}} </span>
                    </div>
                    <div class="block">
                        <span> {{comment.comment}} </span>
                        <!-- <button>مشاهدة التعليق</button> -->
                    </div>
                    <div class="block">
                        
                    </div>
                    <div class="block">
                        <label class="switch">
                            <input type="checkbox"  value="true" v-model="comment.status" @change="() => commentStatus(comment.id, comment.status)">
                            <div class="slider round"></div>
                        </label>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Request from '../../../services/Request';
export default {
    name: 'Comments',
    components: {HeaderBg, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            comments: [],
            comment_status: 0
        }
    },
    mounted(){
        this.getComments();
    },
    methods:{
        getComments(){
            Request.fetch('admin/comments ','')
            .then((response) => {
                console.log(response)
                this.comments = response.data.data.data
                this.loadingRequest = false;
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        commentStatus(id, status){
            var statusId = 0;
            if(status == 0){
                statusId = 0
            } else if(status == 1) {
                statusId = 1
            }
            Request.put('admin/comments', id, {
                status: statusId
            })
            .then((response) => {
                console.log(response)
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.table_header{
    background-color: #FFF;
    display: flex;
    margin-bottom: 20px;
    border-radius: 10px;
    .block{
        width: calc(100% / 6);
        text-align: center;
        padding: 15px 0;
        font-weight: 600;
    }
}
.table_row{
    flex-wrap: wrap;
}
.block{
    width: calc(100% / 6);
    button{
        text-decoration: underline;
        color: #78A28F;
        font-weight: 900;
        font-size: 20px;
        background-color: transparent;
        border: 0;
    }
}
</style>