<template>
    <div>
        <HeaderBg :img="img" title="إضافة عرض" />

        <div class="form_container">
                    <div class="first_section">
                        <div class="input_container">
                            <label>التصنيف :</label>
                            <select v-model="offer.type">
                                <option :value="offersType.key" v-for="(offersType, index) in offersTypes" :key="index">{{offersType.name}}</option>
                            </select>
                        </div>
                        <div class="input_container">
                            <label>طريقة الإضافة</label>
                            <b-form-group class="radiroFlex" v-slot="{ ariaDescribedby }">
                                <b-form-radio :aria-describedby="ariaDescribedby" v-model="addWay" value="addExcel"> إرفاق ملف اكسيل </b-form-radio>
                                <b-form-radio :aria-describedby="ariaDescribedby" v-model="addWay" value="normal"> يدوي </b-form-radio>
                            </b-form-group>
                        </div>
                        <div></div>
                        
                    </div>
                    <div class="first_section">
                        <div class="input_container">
                            <label> اسم العرض باللغة العربية :</label>
                            <input type="text" v-model="offer.translation[0].name">
                        </div>
                        <div class="input_container">
                            <label>اسم العرض باللغة الإنجليزية :</label>
                            <input type="text" v-model="offer.translation[1].name">
                        </div>
                        <div class="input_container">
                            <label>الحالة :</label>
                            <select v-model="offer.status">
                                <option value="1">مفعل</option>
                                <option value="0">غير مفعل</option>
                            </select>
                        </div>
                        <div class="input_container">
                            <label>تاريخ البدء :</label>
                            <date-picker valueType="format" v-model="offer.start_date"></date-picker>
                        </div>
                        <div class="input_container">
                            <label>تاريخ الانتهاء :</label>
                            <date-picker valueType="format" v-model="offer.end_date"></date-picker>
                        </div>
                        
                    </div>
                    
                    <div class="first_section" v-if="addWay == 'normal'">
                        <div class="input_container" v-if="offer.type == 'one_and_other_free' || offer.type == 'one_and_discount_on_second'">
                            <label>الكمية المشترية :</label>
                            <input type="number" v-model="offer.bought_count">
                        </div>
                        <div class="input_container" v-if="offer.type == 'one_and_other_free'">
                            <label>الكمية المجانية :</label>
                            <input type="number" v-model="offer.free_count">
                        </div>

                        <div class="input_container" v-if="offer.type == 'fixed_amount'">
                            <label>مبلغ التخفيض :</label>
                            <input type="number" v-model="offer.discount_amount">
                        </div>
                        <div class="input_container" v-if="offer.type == 'fixed_amount'">
                            <label>الحد الادنى لتطبيق العرض :</label>
                            <input type="number" v-model="offer.min_price_to_apply">
                        </div>


                        <div class="input_container" v-if="offer.type == 'percentage'">
                            <label>نسبة الخصم :</label>
                            <input type="number" v-model="offer.discount_amount">
                        </div>
                        <div class="input_container" v-if="offer.type == 'one_and_discount_on_second'">
                            <label>نسبة الخصم :</label>
                            <input type="number" v-model="offer.discount_on_second_one">
                        </div>
                        <div class="input_container" v-if="offer.type == 'percentage'">
                            <label>الحد الاقصى للخصم :</label>
                            <input type="number" v-model="offer.max_price_to_apply">
                        </div>
                        <div class="input_container" v-if="offer.type == 'percentage'">
                            <label>الحد الادنى لتطبيق الخصم :</label>
                            <input type="number" v-model="offer.min_price_to_apply">
                        </div>
                        <!-- <div class="input_container" v-if="offer.type == 'percentage' || offer.type == 'fixed_amount'">
                            <label>كمية / نسبة :</label>
                            <input type="number" v-model="offer.free_count">
                        </div> -->
                        
                        <div class="input_container">
                            <label>التصنيف :</label>
                            <select v-model="offer.category_id">
                                <option :value="category.id" v-for="(category) in categories" :key="category.id">{{category.translation[0].name}}</option>
                            </select>
                        </div>
                        <div class="input_container w-100 products">
                            <label>المنتجات :</label>
                            <input type="text" v-model="product_name" @change="NameFilter" placeholder="إبحث بأسم المنتج">
                        </div>
                        <div class="products_container">
                            <RequestSpinner v-if="loadingRequest == true" />
                            <div class="single_product" v-for="product in products" :key="product.id" @click="() => addProduct(product.id, product.translation[0].name)">
                                <div class="img_container">
                                    <img :src="product.image" :alt="product.translation[0].name" loading="lazy">
                                </div>
                                <span>{{product.translation[0].name}}</span>
                                <span>{{product.id}}</span>
                            </div>
                        </div>
                        <div class="products_container chosen_products">
                            <div class="single_product" v-for="(product, index) in choosenProducts" :key="product.id">
                                <div>
                                    <span>{{product.name}}</span>
                                    <span>{{product.product_id}}</span>
                                </div>
                                <div class="deleteProduct" @click="deleteProduct(index)">
                                    <b-icon  icon="x" aria-hidden="true"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="first_section" v-if="addWay == 'addExcel'">
                        <p>
                            <span>: خطوة 1</span>
                            الرجاء تنزيل الملف التالي لي النموذج الصحيح لارفاق عروض المنتجات بالشكل المطلوب
                        </p>
                        <button class="downloadFile"> تنزيل لنموزج ملف العروض </button>
                        <p>
                            <span>: خطوة 2</span>
                            اكتب بيانات المنتجات المطلوبة لتحقيق العرض
                        </p>
                        <p>
                            <span>: خطوة 3</span>
                            ارفاق الملف لتحقيق العرض
                        </p>

                        <div class="uploadFile">
                            <div class="upload_img">
                            <span> تحميل صورة الوصفة الطبية</span>
                            <div class="img_container">
                                <img src="../../../assets/images/replaceImg.svg" alt="" v-if="imgUrl == ''">
                                <img :src="imgUrl" alt="" v-if="imgUrl !== ''">
                                <div class="photo_upload">
                                    <div class="upladImg">
                                        <div class="upload">
                                            <label for="">
                                                <img src="../../../assets/images/inputFile.svg" alt="">
                                                <input type="file" v-on="{ change: [uploadUserImg] }">
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                        
                 
                    <div class="alert alert-danger" role="alert" v-if="ErrorCheck == true">
                        <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                    </div>
                    <button @click="addOffer" class="saveBtn" v-if="postLoaded == false">
                        {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                    </button>
                    <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </div>
    </div>
</template>
<script>
import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import Request from '../../../services/Request';
import DatePicker from 'vue2-datepicker';
import store from "@/store/index.js";
export default {
    name :'AddOffer',
    components: {HeaderBg, RequestSpinner, DatePicker},
    data(){
        return{
            img: require('../../../assets/images/prec.png'),
            loadingRequest: false,
            postLoaded: false,
            addWay: 'normal',

            products: [],
            product_name: '',
            choosenProducts: [],
            offersTypes: [],
            categories: [],


            offer: {
                file : '',
                translation : [
                    {
                        name : "",
                        local : "ar"
                    },
                    {
                        name : "",
                        local : "en"
                    }
                ],
                category_id: '',
                type: '',
                status: null,
                start_date: '',
                end_date: '',
                discount_amount: '',
                min_price_to_apply: '',
                max_price_to_apply: '',
                bought_count: '',
                free_count: '',
                discount_on_second_one: '',
            },
            errors: [],
            ErrorCheck: false,
        }
    },
    mounted(){
        this.getOfferTypes();
        this.getCategories();
        this.getOfferData();
    },
    methods:{
        addProduct(id, name){
            this.choosenProducts.push({
                product_id: id,
                name: name,
                amount: 1
            })
        },
        NameFilter(){
            this.products = []
            this.loadingRequest = true;
            Request.getFilteredData('api/product', {
                name: this.product_name
            })
            .then(res => {
                this.products = res.data.data
                this.loadingRequest = false;
            })
        },
        deleteProduct(id){
            this.choosenProducts.splice(id, 1)
        },
        addOffer(){
            this.postLoaded = true;
            const formData = new FormData();
            formData.append('translation[0][name]', this.offer.translation[0].name);
            formData.append('translation[0][local]', this.offer.translation[0].local);
            formData.append('translation[1][name]', this.offer.translation[0].name);
            formData.append('translation[1][local]', this.offer.translation[0].local);
            formData.append('type', this.offer.type);
            formData.append('status', this.offer.status);
            formData.append('start_date', this.offer.start_date);
            formData.append('end_date', this.offer.end_date);
            this.offer.discount_amount !== '' && this.offer.discount_amount !== null && formData.append('discount_amount', this.offer.discount_amount);
            this.offer.min_price_to_apply !== '' && this.offer.min_price_to_apply !== null && formData.append('min_price_to_apply', this.offer.min_price_to_apply);
            this.offer.max_price_to_apply !== '' && this.offer.max_price_to_apply !== null && formData.append('max_price_to_apply', this.offer.max_price_to_apply);
            this.offer.bought_count !== '' && this.offer.bought_count !== null && formData.append('bought_count', this.offer.bought_count);
            this.offer.free_count !== '' && this.offer.free_count !== null && formData.append('free_count', this.offer.free_count);
            this.offer.discount_on_second_one !== '' && this.offer.discount_on_second_one !== null && formData.append('discount_on_second_one', this.offer.discount_on_second_one);
            this.category_id !== '' && this.category_id !== null && formData.append('category_id', this.offer.category_id);
            this.choosenProducts.length > 0 &&
            this.choosenProducts.map((data, index) => {
                formData.append(`products[${index}]`, data.product_id);
            })
            
            if(this.$route.params.id !== undefined){
                formData.append('_method', 'PUT');
                axios.post(`${store.state.domain}/admin/newOffer/${this.$route.params.id}`, formData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/store-admin/offers')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            } else {
                axios.post(`${store.state.domain}/admin/newOffer`, formData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/store-admin/offers')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            }
        },
        getOfferTypes(){
            Request.fetch(`api/newOfferTypes`, '', '')
            .then((response) => {
                console.log(response.data, 'testtttttttttt')
                this.offersTypes = response.data
                
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        getCategories(){
            Request.fetch('admin/category', '', '')
            .then((response) => {
                this.categories = response.data.data
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        getOfferData(){
            if(this.$route.params.id !== undefined){
                Request.fetch(`admin/newOffer/${this.$route.params.id}`, '', '')
                .then((response) => {
                    console.log(response)
                    this.offer.translation[0].name = response.data.data.translation[0].name
                    this.offer.translation[1].name = response.data.data.translation[1].name
                    this.offer.type = response.data.data.type
                    this.offer.status = response.data.data.status
                    this.offer.start_date = response.data.data.start_date
                    this.offer.end_date = response.data.data.end_date
                    this.offer.category_id = response.data.data.category.id
                    this.offer.discount_amount = response.data.data.discount_amount
                    this.offer.min_price_to_apply = response.data.data.min_price_to_apply
                    this.offer.max_price_to_apply = response.data.data.max_price_to_apply
                    this.offer.bought_count = response.data.data.bought_count
                    this.offer.free_count = response.data.data.free_count
                    this.offer.discount_on_second_one = response.data.data.discount_on_second_one
                })
                .catch(err => {
                    if(Request.statusIsFaield(err)){
                        this.$router.push('/login')
                        localStorage.removeItem('token')
                    }
                });
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.mx-datepicker {
    width: 100%;
}
.form_container{
        width: 100%;
            .first_section{
                display: flex;
                justify-content: space-between;
                background-color: #FFF;
                flex-wrap: wrap;
                border-radius: 10px;
                padding: 30px 20px;
                width: 100%;
                margin-bottom: 26px;
                box-shadow: 0px 3px 6px #00000029;
                > p{
                    width: 100%;
                    color: #656565;
                    font-size: 16px;
                    margin-bottom: 30px;
                    span{
                        font-size: 16px;
                        color: #78A28F;
                        margin-left: 20px;
                    }
                }
                .downloadFile{
                    border: 0;
                    background-color: #78A28F;
                    border-radius: 4px;
                    font-size: 16px;
                    color: #FFF;
                    font-weight: 600;
                    text-align: center;
                    padding: 13px 30px;
                    margin: auto;
                    display: inline-block;
                }
            }
           
        .upload_img{
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            width: 100%;
            span{
                font-size: 16px;
                color: #656565;
                margin-left: 50px;
            }
            .img_container{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                // background-color: #FAFAFA;
                background-image: url(../../../assets/images/uploadFile.png);
                background-size: contain;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                img{
                    width: 100%;
                }
                .photo_upload{
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    top: 0%;
                    left: 0;
                    .upladImg{
                        width: 100%;
                        height: 100%;
                        .upload{
                            height: 100%;
                            width: 100%;
                            label{
                                width: 100% !important;
                                height: 100% !important;
                                background-color: transparent;
                            }
                        }
                    }
                    img{
                        width: 70%;
                    }
                }
            }
        }
        .input_container{
            margin-bottom: 16px;
            width: 23%;
            @media (max-width: 1440px) {
                width: 45%;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            label{
                font-size: 14px;
                white-space: nowrap;
                font-weight: 600;
                display: block;
                margin-bottom: 10px;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            input,
            select,
            .b-form-datepicker{
                background-color: #FFF;
                border-radius: 10px;
                padding: 10px;
                font-size: 14px;
                border: 1px solid #C5C5C5;
                width: 100%;
                &::placeholder{
                    font-size: 14px;
                    color: #C5C5C5;
                }
            }
            .b-form-datepicker{
                padding: 5px;
            }
        }
    }

    .products_container{
            border: 1px solid #cecece;
            border-radius: 10px;
            padding: 10px;
            margin-bottom: 40px;
            height: 200px;
            overflow-y: scroll;
            width: 100%;
            .single_product{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                padding: 5px 10px;
                .img_container{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-left: 20px;
                    img{
                        width: 100%;
                    }
                }
                &:hover{
                    background-color: #78A28F;
                }
                cursor: pointer;
                span{
                    font-size: 12px;
                    font-weight: 900;
                    color: #1E1E1E;
                    margin-left: 20px;
                }
                .deleteProduct{
                    border-radius: 2px 4px;
                    cursor: pointer;
                    padding: 8px;
                    background-color: red;

                    svg{
                        font-size: 20px;
                        color: #FFF;
                    }
                }
            }
        }
        .chosen_products .single_product{
            cursor: default !important;
            &:hover{
                background-color: transparent !important;
            }
        }
</style>