<template>
    <div>
        <HeaderBg :img="img" title="ربط الحي" />

        <div class="form_container">
            <form @submit.prevent="linkDistrict">
                <div class="input_container">
                    <label> الفروع </label>
                    <select v-model="link.branch_id">
                        <option :value="branche.id" v-for="branche in branches" :key="branche.id"> {{branche.translation[0].name}} </option>
                    </select>
                </div>
                <div class="input_container mt-3">
                    <label> المدن </label>
                    <select v-model="city" @change="(e) => getDistrict(e.target.value)">
                        <option :value="city.id" v-for="city in cities" :key="city.id"> {{city.translation[0].name}} </option>
                    </select>
                </div>
                <div class="input_container mt-3">
                    <label> الأحياء </label>
                    <vue-tags-input
                        v-model="tag"
                        :tags="tags"
                        :autocomplete-items="filteredItems"
                        @tags-changed="newTags => tags = newTags"
                        :autocomplete-min-length="0"
                        autocomplete="off"
                        placeholder="الأحياء"
                    />
                </div>
                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import VueTagsInput from '@johmun/vue-tags-input';
import Request from '../../../services/Request';

export default {
    name :'LinkDistrict',
    components: {HeaderBg, VueTagsInput},
    data(){
        return{
            img: require('../../../assets/images/locationLarg.png'),
            postLoaded: false,
            branches: [],
            cities: [],
            tag: '',
            tags: [],
            autocompleteItems: [],
            link:{
                branch_id : null,
                district_id : []
            },
            city: '',

            errors: [],
            ErrorCheck: false,
        }
    },
    mounted(){
        this.getCities();
        this.getLinkDistrict();
        this.getBranches();
    },
    methods:{
        getLinkDistrict(){
            if(this.$route.params.id !== undefined){
                axios.get(`https://app.almujtama.com.sa/admin/branchDistrict/${this.$route.params.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        'Authorization': 'Bearer '+ localStorage.getItem('token'),
                    },
                })
                .then((response) => {
                    this.getDistrict(response.data.data.city.id)
                    this.link.branch_id = response.data.data.branch.id
                    this.city = response.data.data.city.id
                    this.link.district_id.push(response.data.data.district.id)
                })
                .catch(err => {
                    if(Request.statusIsFaield(err)){
                        this.$router.push('/login')
                        localStorage.removeItem('token')
                    }
                });
            }
        },
        getCities(){
            axios.get(`https://app.almujtama.com.sa/admin/city`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                this.cities = response.data.data
                this.loadingRequest = false;
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        getDistrict(id){
            axios.get(`https://app.almujtama.com.sa/admin/district`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
                params:{
                    city_id: id
                }
            })
            .then((response) => {
                response.data.data.map( data => {
                    const autocompleteItems = [...this.autocompleteItems,  {
                            'id': data.id,
                            'text': 
                            data.translation[0].name
                        } ]

                    this.autocompleteItems = autocompleteItems;
                } )
                console.log(this.tags, 'tags')
                // this.districts = response.data.data
                // this.loadingRequest = false;
            
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        getBranches(){
            Request.fetch('admin/branch', '', '')
            .then((response) => {
                this.branches = response.data.data
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        linkDistrict(){
            this.postLoaded = true;
            this.tags.map(data => {
                this.link.district_id.push(data.id)
            })
            if(this.$route.params.id !== undefined){
                axios.put(`https://app.almujtama.com.sa/admin/branchDistrict/${this.$route.params.id}`, this.link, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/store-admin/linking-disticts')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    console.log(err.response.data.errors)
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            } else {
                axios.post('https://app.almujtama.com.sa/admin/branchDistrict', this.link, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/store-admin/linking-disticts')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    console.log(err.response.data.errors)
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            }
        }
    },
    computed: {
        filteredItems() {
            return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.form_container{
    background-color: #FFF;
    border-radius: 10px;
    margin: 40px auto;
    padding: 70px 100px;
    width: 482px;
    @media (max-width: 576px) {
        width: 97%;
        padding: 30px;
    }
    form{
        > div{
            label{
                display: block;
                text-align: center;
                font-size: 16px;
                color: #717171;
                font-weight: 600;
                margin-bottom: 10px;
            }
            input,
            select{
                border: 1px solid #71717191;
                font-size: 16px;
                padding: 5px 10px;
                border-radius: 10px;
                display: block;
                margin: auto;
                width: 100%;
            }
        }
        button{
            margin: 30px auto 0 auto;   
        }
    }
}
</style>