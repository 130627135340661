<template>
    <div>
        <!-- ArticlesSections.vue -->
        <HeaderBg title="أقسام فرعية" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteCourse" 
            messege="هل أنت متأكد من مسح القسم   ؟"
        />
        <header class="admin_content_header">
            <h2></h2>
            <div class="search">
                
            </div>
            <router-link :to="'/store-admin/sub-category/'+this.$route.params.id+'/add-sub-category'"> أضف قسم فرعي جديد </router-link>
        </header>

         <div class="divsTable">
            <div class="line_row">
                <h3>الأقسام الفرعية</h3>
            </div>
            <div class="line_row" v-for="sub in subCategory" :key="sub.id">
                <h4>{{sub.translation[0].name}}</h4>
                <div class="actionsContainer">
                    <div class="options_container">
                        <!-- <img src="../../../assets/images/selectIcon.png" alt="">
                        <div class="hidden_options">
                            <button @click="() => editArticleSubCategory(sub.id)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                            <button @click="() => deleteData(sub.id)"> <img src="../../../assets/images/delete-text.png" alt=""> حذف </button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
import Request from '../../../services/Request';
import axios from 'axios';
export default {
    name: 'SubCategory',
    components: {HeaderBg, Alert},
    data(){
        return{
            subCategory: [],
            deleteID: '',
            alertToggle: false,
        }
    },
    mounted(){
        this.getSubCategory();
        localStorage.removeItem('editSubCategory')
    },
    methods:{
        getSubCategory(){
            axios.get(`https://app.almujtama.com.sa/admin/subcategory`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
                params:{
                    category_id : this.$route.params.id
                }
            })
            .then((response) => {
                this.subCategory = response.data.data
            
            })
        },
        editArticleSubCategory(id){
            this.$router.push(`/store-admin/sub-category/${this.$route.params.id}/add-sub-category/${id}`)
            localStorage.setItem('editSubCategory', 'edit')
        },
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteCourse(){
            Request.delete('admin/subcategory',this.deleteID)
            .then( () => {
                this.getSubCategory();
            })
        },
    }
}
</script>
<style lang="scss" scoped>

</style>