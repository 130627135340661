
<template>
  <gmap-map
    :center="center"
    :zoom="16"
    style="width: 100%; height: 500px"
  >
    <gmap-marker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :title="m.title"
      :clickable="true"
      :draggable="true"
      @click="center=m.position"
    ></gmap-marker>
  </gmap-map>
</template>
<script>
  export default {
    data () {
      return {
        
        center: {lat: this.locations[0].position.lat, lng: this.locations[0].position.lng},
        markers: this.locations
      }
    },

    props: {
        locations: Array
    }
  }
</script>
