
<template>
    <!-- Branches.vue -->
    <div>
        <HeaderBg :img="img" title="إدارة الفروع" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteCourse" 
            messege="هل أنت متأكد من مسح الفرع   ؟"
        />
        <header class="admin_content_header">
            <div class="filter">
                <select>
                    <option value="" selected disabled>المدينة</option>
                </select>
            </div>
            <div class="search">
                <form @submit.prevent="NameFilter">
                    <div class="inputSearch">
                        <input type="text" placeholder="البحث بالإسم" v-model="branchName">
                        <button class="searchBtn"><b-icon icon="search" aria-hidden="true"></b-icon></button> 
                    </div>
                </form>
            </div>
            <router-link to="/admin/add-branch" v-if="add_branch_permission == true"> + أضف فرع جديد </router-link>
            
        </header>

        <RequestSpinner v-if="loadingRequest == true" />

        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> رقم الفرع</th>
                        <th> أسم الفرع</th>
                        <th> الهاتف</th>
                        <th> المدينة</th>
                        <th> </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="branche in branches" :key="branche.id">
                        <td>{{branche.code}}</td>
                        <td>{{branche.translation[0].name}}</td>
                        <td>{{branche.mobile}}</td>
                        <td>{{branche.city}}</td>
                        <td>
                            <div class="options_container">
                                <img src="../../../assets/images/selectIcon.png" alt="">
                                <div class="hidden_options">
                                    <button v-if="edit_branch_permission == true" @click="() => editBranch(branche.id)"> <img src="../../../assets/images/edit-text.png" alt=""> تعديل  </button>
                                    <button v-if="delete_branch_permission == true" @click="() => deleteData(branche.id)"> <img src="../../../assets/images/delete-text.png" alt="" > حذف </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="branches.length >= 1 && loadingRequest == false">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Alert from '../../global/Alert/Alert';
import Request from '../../../services/Request';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import store from "@/store/index.js";
export default {
    name:'Branches',
    components: {HeaderBg, Alert, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            img: require('../../../assets/images/branches-main-logo.png'),
            branches: [],
            deleteID: '',
            alertToggle: false,
            branchName: '',

            // permissions
            add_branch_permission: false,
            edit_branch_permission: false,
            delete_branch_permission: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    beforeMount(){
        var permissions = JSON.parse(localStorage.getItem("permissions"));

        if(permissions.includes('list branches')){
            console.log('permission')
        } else {
            this.$router.push('/login')
        }

        if(permissions.includes('add branch')){
            this.add_branch_permission = true
        } 
        if(permissions.includes('edit branch')) {
            this.edit_branch_permission = true
        } 
        if(permissions.includes('delete branch')) {
            this.delete_branch_permission = true
        } 
    },
    mounted(){
        // get pagination page number from url
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        if(page){
            this.currentPage = page
        }
        localStorage.removeItem('editBranch')
        this.getBranches();
    },
    methods:{
        getBranches(){
            Request.fetch('admin/branch', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.branches = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
        },
        editBranch(id){
            this.$router.push(`/admin/add-branch/${id}`)
        },
         // delete data
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteCourse(){
            Request.delete('admin/branch',this.deleteID)
            .then( () => {
                this.getBranches();
            })
        },
        NameFilter(){
            this.loadingRequest = true;
            Request.fetch('admin/branch', this.apiParams , {
                limit: store.state.limit,
                name: this.branchName
            })
            .then((response) => {
                this.branches = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/')
                    localStorage.removeItem('token')
                }
            });
            // .catch(err => this.setErrorResponse(err))     
        },
        CityFilter(){
            Request.getFilteredData('admin/branch', {
                city: this.city
            })
            .then(res => {
                console.log(res)
                this.branches = res.data.data
            })
            // .catch(err => this.setErrorResponse(err))     
        },
        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getBranches();
            } else{
                this.apiParams = `?page=${val}`;
                this.getBranches();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.filter{
    select{
        background-color: #FFF;
        border: 0;
        width: 89px;
        text-align: center;
        font-size: 20px;
        border-radius: 10px;
    }
}

</style>